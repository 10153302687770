.bit-banner-info {
  border-radius: 4px;
  display: flex;
  padding: 12px 20px;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: var(--modal-orange-low);
}

.bit-banner-info div {
  margin-left: 12px;
}
