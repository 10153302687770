.index-recurring .content-header {
  height: 56px;
}

.recurring .content-box {
  height: calc(100% + 44px) !important;
  position: relative;
  margin-top: -44px;
}

.recurring-portfolio-row .am-checkbox-inner {
  border: 0px solid #ccc;
  right: -18px;
  top: 50px;
  z-index: 1;
}

.recurring-portfolio-row__disabled img[alt='icon-checkbox'] {
  display: none;
}

.recurring-portfolio-row .am-checkbox-inner:after {
  top: 3px;
  right: 6px;
}

label.am-checkbox-wrapper.recurring-portfolio-row {
  text-align: center;
}

.recurring-portfolio-row-checkbox {
  min-height: 90px;
  margin-bottom: 8px;
  flex-direction: column;
}

.recurring-portfolio-row-checkbox > input + div {
  border: none;
  position: absolute;
  top: 38px;
  z-index: 1;
  transform: translate(18px, 0);
}

.recurring-portfolio-row-checkbox > input + div + div {
  width: 100%;
  text-align: center;
  padding-left: 0;
}

.recurring-porto-chosen-wrapper {
  border: 1px solid var(--border);
  border-radius: 5px;
  margin-top: 8px;
}

.date-first-recurring-loader {
  margin-bottom: 16px;
  width: 100%;
}

.date-first-recurring {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--modal-border);
  border-radius: 4px;
  height: 36px;
  margin-bottom: 12px;
  align-items: center;
}

.date-first-recurring h1 {
  font-size: 14px;
  color: var(--text-secondary);
  line-height: 20px;
  font-weight: 400;
  margin-right: 5px;
}

.date-first-recurring span {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-primary);
}

.recurring-roboitem > .recurring-product-row {
  border-radius: 0;
  border-collapse: collapse;
  border-bottom: 1px solid var(--border) !important;
  border: none;
}
.recurring-roboitem {
  border: 1px solid var(--border);
  border-left: none;
  border-radius: 5px;
}

.recurring-roboitem > .recurring-product-row > div > svg {
  stroke: var(--icon-default);
}

.recurring-roboitem > .recurring-product-row:first-child {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.recurring-roboitem > .recurring-product-row:last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom: none !important;
}

.bit-input-payment-method-rec
  .am-accordion
  .am-accordion-item
  .am-accordion-header
  i {
  right: 20px;
}

.bit-recurring-product-chosen {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  padding: 10px 14px;
}

.bit-recurring-product-chosen .bit-recurring-product-left {
  display: flex;
  align-items: center;
  width: calc(100% - 20px);
}

.bit-recurring-product-chosen .bit-recurring-product-left p {
  flex: 1;
  margin-right: 32px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bit-recurring-product-chosen .bit-product-chosen-check {
  padding: 2px;
  background-color: #00ab6b;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bit-recurring-product-chosen img {
  height: 24px;
  margin-right: 10px;
  width: 24px;
}

.bit-recurring-select-input-loader {
  display: flex;
  align-items: center;
  width: 100%;
}

.bit-recurring-select-input-loader > div:first-child {
  height: 22px;
  width: 22px;
  border-radius: 50%;
}

.bit-recurring-select-input-loader > div:nth-child(2) {
  width: 60%;
  margin: 5px 10px;
  height: 10px;
}

.bit-invest-recurring {
  background: transparent;
  border: none;
  border: 1px solid var(--border);
  border-radius: 5px;
  color: var(--text-primary);
  font-size: 18px !important;
  font-weight: bold;
  margin-bottom: 2px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 9px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.va-icon-recurring {
  margin-right: 14px;
  width: 42px;
}

.recurring-opt-row .am-checkbox-disabled {
  display: none;
}

.recurring-opt-row .chip-restrict-min-max {
  font-size: 12px;
  padding: 4px 12px;
  background-color: var(--cobalt-low);
  border-radius: 20px;
  color: var(--cobalt-default);
  font-weight: 700;
}

.recurring-opt-row .chip-restrict-min {
  font-size: 12px;
  padding: 4px 12px;
  background-color: var(--cobalt-low);
  border-radius: 20px;
  color: var(--cobalt-default);
  font-weight: 700;
}

.recurring-opt-row .chip-restrict-max {
  font-size: 9px;
  padding: 4px 12px;
  background-color: var(--cobalt-low);
  border-radius: 20px;
  color: var(--cobalt-default);
  font-weight: 700;
  word-break: break-all;
}

.recurring .am-navbar {
  background: var(--green-medium);
}

.recurring-detail-appnavbar.transparent > .am-navbar-title {
  transition: all 0.5s ease-out;
}

:global .modal-input-payment :global .payment-method-divider {
  background-color: var(--modal-border);
}
.recurring-detail-appnavbar.transparent > .am-navbar-title {
  opacity: 0;
}

.recurring-navbar-image {
  background: var(--green-low);
  transition: all 0.5s ease-out;
  background-size: 100%;
  position: fixed;
  top: 0;
  height: 56px;
  width: 100%;
  z-index: 899;
}

.index-recurring--dark .recurring-navbar-image {
  background: var(--green-low);
}

.recurring-navbar-image.transparent {
  opacity: 0;
}

.recurring-detail-appnavbar.filled {
  opacity: 1;
}

.recurring-detail-appnavbar .am-navbar-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-word;
}

.recurring-status-button {
  display: flex;
  align-items: center;
  color: #00ab6b;
}

.recurring-status-button svg {
  margin-left: 8px;
}

.recurring-list-porto {
  margin-top: 310px;
}

/* from old recurring.css  */

.bit-recurring {
  padding: 0 0 16px;
}

.bit-recurring label {
  color: var(--text-primary);
  display: block;
  font-size: 13px;
  padding: 20px 0 10px 0;
}

.bit-recurring label:nth-child(1) {
  padding: 10px 0 10px 0;
}

.bit-invest-recurring::placeholder {
  font-size: 14px;
  font-weight: normal;
}

.recurring-payment-method:empty {
  min-height: 40px;
}

.bit-recurring-btn {
  font-size: 14px !important;
  font-weight: bold;
}

.recurring-portfolio-wrap {
  padding: 14px 20px 0;
}

.recurring-portfolio-wrap h3 {
  font-size: 14px;
  margin-bottom: 8px;
}

.recurring-portfolio-img {
  border-radius: 50%;
  height: 50px;
  margin: 0px auto 8px;
  width: 50px;
  position: relative;
}

.recurring-portfolio-name {
  color: var(--text-primary);
  width: 100%;
  word-break: break-word;
  overflow: hidden;
}

.recurring-portfolio-img .shared-icon {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 18px;
  justify-content: center;
  left: -2px;
  position: absolute;
  top: 0;
  width: 18px;
  padding-top: 4px;
}

.recurring-portfolio-img .shared-icon > img {
  width: 14px;
  height: 14px;
}

.recurring-portfolio-img img {
  width: 100%;
  border-radius: 50%;
}

.recurring-portfolio-row .am-checkbox-inner {
  border: 0px solid #ccc;
  right: -18px;
  top: 50px;
  z-index: 1;
}

.recurring-portfolio-row .am-checkbox-inner:after {
  top: 3px;
  right: 6px;
}

label.am-checkbox-wrapper.recurring-portfolio-row {
  text-align: center;
  padding: 0 2px;
}

.calendar-sync img {
  width: 17px !important;
}

.recurring-detail-wrap {
  display: flex;
  flex-direction: column;
  margin: 16px 16px 18px;
}

.recurring-wrapper {
  border: 1px solid var(--modal-border);
  border-radius: 4px;
}

.space-banner-button {
  margin-bottom: 20px;
}

.recurring-flex-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
  gap: 10px;
}

.recurring-flex-row p:first-child {
  color: var(--text-secondary);
  text-align: left;
}

.recurring-flex-row p:last-child {
  color: var(--text-primary);
  font-weight: bold;
}

.recurring-flex-row .bit-rec-flex > div {
  color: var(--text-primary);
  margin-right: 0;
  text-align: right;
}

.rounded-btn {
  width: fit-content;
  border-radius: 30px !important;
  margin: auto;
  padding: 0 50px !important;
  text-align: center;
  font-size: 14px;
}

.rec-display-none {
  display: none;
}

.rec-noradius-bottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rec-learn-more {
  color: #00ab6b !important;
  display: block;
  font-size: 13px !important;
  margin-top: 16px;
}

.cont-height-100 {
  text-align: center;
}

.rec-height-100 {
  height: 100%;
  text-align: center;
}

.bit-recurring h3 {
  text-align: left;
}

.cust-twins,
.cust-twins-no-color {
  display: flex;
}

.cust-twins .am-button::before,
.cust-twins-no-color .am-button::before {
  border: 0 !important;
}

.cust-twins .am-button,
.cust-twins-no-color .am-button {
  width: calc(50% - 1px);
  border: solid 1px #e4e4e4 !important;
  border-radius: 0 !important;
  font-size: 14px;
}

.cust-twins .am-button:first-child {
  border-radius: 5px !important;
  color: #ee4a49;
  margin-right: 10px;
}

.cust-twins .am-button:last-child {
  border-radius: 5px !important;
  border-left: 1px solid #eee !important;
  color: #00ab6b;
  margin-left: 10px;
}

.cust-twins-no-color .am-button:first-child {
  border-radius: 5px !important;
  margin-right: 10px;
}

.cust-twins-no-color .am-button:last-child {
  border-radius: 5px !important;
  border-left: 1px solid #eee !important;
  margin-left: 10px;
}

.cust-modal-close .am-modal-content {
  border-radius: 0 !important;
}

.bit-other-bank-pic {
  margin-bottom: -4px;
  margin-left: -12px;
  margin-right: -2px;
  margin-top: -4px;
  width: 50px;
}

.recurring-payment-method.bit-edit-rec label {
  font-weight: normal;
}

.border-top-5-gray {
  border-top: 5px solid var(--border);
}

.border-top-8-gray {
  border-top: 8px solid var(--border);
}

.porto-wrapper-before-choose .bit-recurring-product {
  padding: 10px 0 5px;
}

.bit-input-payment-method-rec .recurring-payment-method div img.va-icon {
  object-fit: contain;
  margin-right: 14px;
  width: 42px;
}

.recurring-payment-method div img.va-icon {
  object-fit: contain;
  margin-right: 12px;
}

/* Confrimation modal recurring */
.bit-modal-confirmation-recurring .bit-recurring-product {
  padding: 10px 0 0px;
}

.bit-modal-confirmation-recurring .bit-recurring-product-box {
  margin-top: 5px;
}

.bit-modal-confirmation-recurring .recurring-detail-wrap {
  border: 1px solid #ededed;
  border-top: 0;
  border-radius: 0 0 4px 4px;
  margin: -2px 0 18px;
  padding: 5px 17px;
}

.bit-modal-confirmation-recurring .bit-recurring-product-box {
  border-radius: 5px 5px 0 0;
}

.recurring-pay-shopeepay {
  width: 71px;
  height: auto;
}

.gopay-background-color {
  background-color: var(--blue-medium);
  color: var(--text-primary);
}

.choose-product-modal
  .content
  .bit-container-padding-horizontal:first-child
  .bit-uikit-custom {
  display: none;
}

.choose-product-modal .bit-modal-header,
.choose-recurring-modal
  .bit-reksa-cards-wrap
  .bit-container-padding-horizontal {
  padding: 0 0 20px 0;
}
.choose-recurring-modal .content-wrapper{
  background-color: var(--modal-surface);
}
.choose-recurring-modal .content-box{
  background-color: var(--modal-surface);
}
.choose-recurring-modal .puller-box{
  background-color: var(--modal-surface);
}


.choose-recurring-modal .bit-modal-header {
  padding-bottom: 12px;
}

.choose-product-modal .content {
  padding-bottom: 20px;
}

.recurring-explore-product ~ .bit-explore-search .bit-search-empty {
  height: calc(100% - 55px);
}

.recurring-explore-product ~ .bit-explore-search .bit-explore-search-wrapper {
  height: 100vh;
}

.recurring-explore-product ~ .bit-explore-search .bit-search-history {
  height: calc(100vh - 79px);
}

.modal-porto-recurring .bit-modal-header {
  padding-bottom: 16px;
}

@media only screen and (min-width: 1030px) {
  .recurring-explore-product ~ .bit-explore-search .bit-search-history {
    height: calc(100vh - 148px);
  }

  .bit-recurring-robo-head {
    border-bottom: 1px solid #f0f0f0;
    border-top: 1px solid #f0f0f0;
  }

  .recurring-payment-method .recurring-opt-row {
    cursor: pointer;
  }

  .recurring-payment-method:empty {
    background: none;
  }

  .rec-height-100 {
    height: calc(100vh - 160px);
  }

  .am-modal.cust-modal-close {
    width: 768px !important;
    height: 100vh !important;
    margin: 0 auto;
    border-radius: 8px;
    overflow: hidden;
  }

  .am-modal.cust-modal-close .get-started .am-card {
    width: calc(100% - 60px);
  }

  .am-modal.cust-modal-close .get-started {
    padding-bottom: 10px;
  }

  .am-modal.cust-modal-close .get-started-banner {
    margin-bottom: 20px;
  }

  .index-recurring .content-box {
    padding-bottom: 0;
  }

  .index-recurring .content-header {
    background-color: var(--surface);
  }

  .bit-recurring-modal-confirm {
    padding: 0px 20px 16px 20px;
  }

  .recurring-list-porto {
    margin-top: 360px;
    height: 100%;
    background-color: var(--surface);
  }
}

@media (max-width: 360px) {
  .bit-rec-img {
    width: 65px;
  }

  .recurring-banner-text {
    margin: 0 8px 0 10px;
  }

  .recurring-flex-row p:last-child {
    text-align: right;
  }
}

.empty-payment-recurring {
  display: flex;
  align-items: center;
  padding-left: 12px;
  color: #626262;
}

.recurring-non-active {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(238, 74, 73, 0.2);
  border-radius: 4px;
  padding: 2px 8px;
}

.porto-wrapper-recurring {
  border: 1px solid #ededed;
  border-radius: 5px;
}

.payment-method-scroll {
  max-height: calc(100vh - 62px);
  overflow-y: auto;
}
.payment-method-divider {
  height: 8px;
  background-color: var(--border);
}

.accordion-pay-recurring .am-accordion-header::after {
  background: none !important;
}

.accordion-pay-recurring .am-accordion-header {
  padding: 20px 20px 10px 20px !important;
}

.accordion-pay-recurring .grayscale-payment {
  filter: grayscale(100%);
  color: #949494;
}

.accordion-pay-recurring .am-checkbox.am-checkbox-disabled {
  display: none;
}

.accordion-pay-recurring .chip-restrict-min-max {
  font-size: 12px;
  padding: 4px 12px;
  background-color: #d4e9f6;
  border-radius: 20px;
  color: #2479b4;
  font-weight: 700;
}

.recurring-error-wrapper {
  padding: 32px 20px 24px;
}

.recurring-error-wrapper p {
  margin-top: 10px;
}

.bit-input-payment-method-rec .payment-method-scroll .am-accordion-header {
  height: auto;
}

.bit-input-payment-method-rec .am-accordion-header p {
  white-space: break-spaces;
}

.recurring-choose-product-modal .get-started {
  overflow-y: auto;
}

/* Custom Collapse style for panel */
.bit-payment-method-rec-collapse {
  padding: 20px 20px 10px 20px;
}

.bit-title-method-card {
  color: var(--text-primary);
}
.bit-desc-method-card {
  color: var(--text-secondary);
}

.bit-collapse-list-wrap {
  padding: 10px 0;
  background-color: var(--modal-surface);
}

.bit-payment-method-rec-collapse .bit-uikit-collapse-header > div {
  border: none;
  background-color: var(--modal-surface);
  align-items: flex-start;
}
.bit-payment-method-rec-collapse .bit-uikit-bg-white,
.bit-payment-bank-transfer .bit-uikit-bg-white {
  background-color: var(--modal-surface);
}

.bit-input-payment-method-rec .bit-uikit-bg-white {
  background-color: var(--modal-surface);
}

@media only screen and (max-width: 320px) {
  .recurring-navbar-image {
    background-size: 112%;
  }
}

@media only screen and (min-width: 320px) {
  .date-first-recurring {
    padding: 8px 5px 8px 7px;
  }
}

@media only screen and (min-width: 375px) {
  .date-first-recurring {
    padding: 8px 18px 8px 20px;
  }
}

@media only screen and (min-width: 1030px) {
  .recurring-choose-product-modal .get-started .am-card {
    width: calc(100% - 60px);
  }

  .recurring-detail-appnavbar .am-navbar-title {
    display: -webkit-flex;
  }

  .recurring-navbar-image {
    width: 768px;
    height: 80px;
  }

  .recurring-detail-appnavbar.filled {
    background-color: transparent;
  }

  .recurring-choose-product-modal .content-box {
    margin-top: 0;
  }

  .recurring-create-update .content-header {
    background-color: #f9f9f9;
  }

  .choose-product-modal .content {
    padding: 0 20px 20px 20px;
  }
}
