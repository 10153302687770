.modal-sell-list .slider-wrap {
  height: 1px;
  width: calc(100% - 60px);
}
.modal-sell-list .slider-wrapFull {
  width: calc(100% - 60px) !important;
  height: auto;
}

.portfolio-detail-modal .porto-card-top {
  position: relative;
  padding: 16px;
  margin-bottom: -10px;
  border-top: 1px solid var(--modal-border);
  border-left: 1px solid var(--modal-border);
  border-right: 1px solid var(--modal-border);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.portfolio-detail-modal .porto-card-top .porto-card-logo,
.portfolio-detail-modal .porto-card-top .porto-card-name {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.portfolio-detail-modal .porto-card-top .lineborder {
  position: absolute;
  width: 4px;
  height: 94%;
  border-top-left-radius: 5px;
  top: 0;
  left: 0;
  bottom: 0;
}

.portfolio-detail-modal .porto-card-top .lineborder.moneymarket {
  background: var(--green-default);
}
.portfolio-detail-modal .porto-card-top .lineborder.fixincome {
  background: var(--modal-navy-default);
}
.portfolio-detail-modal .porto-card-top .lineborder.equity {
  background: var(--purple-default);
}

.modal-sell-list.two-cols {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-sell-list.two-cols input {
  color: var(--text-primary);
  text-align: right !important;
}

.sell-unit-val input {
  text-align: center;
  border-radius: 2px;
  width: 100px;
}

.portfolio-detail-modal .porto-card-name > div:nth-child(1) {
  margin: unset !important;
  display: flex;
  align-items: center;
  width: 100%;
}

.portfolio-detail-modal .porto-card-name > div:nth-child(1) img {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: 8px;
}

.portfolio-detail-modal .porto-card-name > div:nth-child(2) {
  padding-top: 0;
}

.numbOnModal {
  color: var(--text-primary);
  font-size: 16px;
  padding-right: 5.5px;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
.twoColsWIn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.twoColsWIn > div:nth-child(1) {
  right: 0;
  position: absolute;
  width: 75px;
  font-size: 13px;
  color: var(--text-secondary);
}
.twoColsWIn > div:nth-child(2) {
  width: 100%;
}
.twoColsWIn > * > input {
  width: 100%;
}

.pemilik-rekening {
  padding-top: 10px;
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
}

.pemilik-rekening-title {
  color: #a5a5a5;
  font-size: 12px;
  padding: 5px 0;
}
.pemilik-rekening > div:nth-child(2) {
  margin-left: 15px;
  padding-left: 15px;
  border-left: solid 1px #ededed;
}

.pemilik-rekening > div:nth-child(1) img {
  height: 40px;
}

.modal-sell-list .porto-card-logo {
  width: 50px;
}

.am-modal-body {
  font-size: 14px !important;
}

.sell-pin-fix .am-modal-body {
  height: auto;
}

.sell-unit-val input {
  margin-bottom: -2px;
}
.nilai-jual input {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.nilai-jual input {
  min-width: 100%;
  width: 100%;
}

.sell-unit-input.nilai-jual {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.sell-unit-input input {
  border: 0;
  border-bottom: 1px solid var(--modal-border);
  border: 1px solid var(--modal-border) !important;
  padding: 10px 70px 10px 5px;
  background: var(--modal-surface);
  text-align: right;
  box-shadow: none;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  border-radius: 2px;
  font-weight: bold;
}

.prox-condensed-bold {
  font-family: 'Proxima Nova Cn Rg', 'Proxima Nova Rg', sans-serif;
}
.pemilik-rekening-box {
  border: 1px solid var(--modal-border);
  border-radius: 5px;
  display: flex;
  margin-top: 16px;
  padding: 10px 10px 12px;
  position: relative;
  width: 100%;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.pemilik-rekening-box.loading-data {
  opacity: 0.5 !important;
  filter: grayscale(100%);
}

.bit-first-rek-box {
  border-right: 1px solid var(--modal-divider-thin);
  margin-right: 16px;
  width: 50%;
  padding-right: 4px;
}
.bit-last-rek-box .paynote-1,
.bit-first-rek-box .paynote-1 {
  font-weight: bold;
  line-height: 20px;
  margin-top: 3px;
  height: 24px;
}

.bit-first-rek-box,
.bit-last-rek-box {
  text-align: left;
}
.bit-first-rek-box::after {
  background: url(./../../assets/images/sell-asset/bit-right-arrow.svg)
    no-repeat;
  position: absolute;
  content: ' ';
  height: 20px;
  width: 20px;
  z-index: 2;
  left: 50%;
  margin-left: -16px;
  top: 50%;
  transform: translateY(-50%);
}
.bit-last-rek-box {
  width: 50%;
}
.bit-last-rek-box .paynote-3 {
  font-weight: normal;
}

.bit-margin-top-10 {
  margin-top: 10px;
}
.bit-margin-top-16 {
  margin-top: 16px;
}

.bit-flex-paynote {
  display: flex;
  flex-wrap: wrap;
}
.bit-flex-paynote-custom {
  flex-wrap: nowrap !important;
}
.paynote-wrapper {
  display: inline !important;
  margin-bottom: 10px;
}

.single-paynote-wrapper {
  display: inline;
}

.rex-box-padding-10 {
  padding-right: 10px;
}

.paynote .single-paynote-wrapper span {
  display: inline;
  line-height: 12px;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
.paynote .single-paynote-wrapper span.paynote-empty {
  display: inline-block;
  margin-bottom: 4px;
  width: 14px;
}

.bit-flex-paynote span {
  margin-bottom: 5px;
  line-height: 12px !important;
  font-weight: bold;
  display: inline;
  box-shadow: 0px 0 0, 0px 0 0;
  box-decoration-break: clone;
}

.pemilik-rekening + a {
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
.bit-sell-wrap {
  padding: 20px 0;
}
.bit-sell-wrap-title h3 {
  margin-bottom: 0;
  margin-top: 0;
  margin-right: 34px;
  line-height: 22px;
}
.bit-sell-article {
  color: var(--text-primary);
}
.bit-sell-article > div {
  align-items: center;
  display: flex;
}
.bit-sell-article-wrapper {
  overflow: auto !important;
  align-items: flex-start !important;
}
.bit-sell-article > div img {
  margin-right: 20px;
}
.bit-sell-article > div h3 {
  font-size: 15px;
  margin-bottom: 5px;
}
.bit-sell-article > div p {
  font-size: 13px;
  color: var(--text-secondary);
}
.bit-sell-opt {
  border: 1px solid #e4e4e4;
  border-radius: 30px;
  padding: 8px 16px;
  margin-bottom: 14px;
}
.bit-sell-overlay {
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.bit-sell-modal {
  background: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  bottom: 0;
  position: absolute;
}
.bit-close-sell {
  position: absolute;
  right: 10px;
  top: 10px;
}
.modal-sell-list .am-slider {
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
.percentage-slider {
  margin-left: 6px;
}
.percentage-slider .am-slider-rail {
  background-color: var(--modal-border);
}
.bit-sell-center,
.bit-sell-checkbox {
  margin: 20px 0;
  text-align: center;
  min-height: 1px;
}
.bit-sell-checkbox > div:nth-child(1) .disabled > div:nth-child(2) > img {
  display: none;
}
.bit-sell-checkbox {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin: 0px;
}
.bit-sell-center img {
  width: 160px;
}
.bit-sell-center label,
.bit-sell-checkbox label {
  color: #b5b5b5;
  display: flex;
}
.bit-sell-center label p {
  font-size: 13px;
  margin: 10px 0 4px 10px;
}
.bit-sell-checkbox label p {
  font-size: 13px;
  margin-left: 10px;
  color: var(--text-primary);
}
.bit-sell-checkbox label > input + div {
  width: 17px;
  height: 17px;
  border-color: var(--inactive);
}
.bit-sell-checkbox label > input + div > img {
  display: none;
}
.bit-sell-checkbox label > input:checked + div > img {
  display: block;
}
.bit-sell-center .am-checkbox-inner:after {
  top: 2.5px;
  right: 5.5px;
}
.robosell-warning-modal {
  height: 100vh;
}
.robosell-warning-modal-auto,
.robosell-virtual-height,
sell-change-bank-modal-wrapper {
  height: auto;
  max-height: 100vh;
}
.robosell-body {
  padding: 0px 0px 20px 0px !important;
}
.robosell-body .sell-modal-container .sell-modal-detail {
  overflow: unset;
  padding-bottom: 0;
}
.sell-modal-confirmation-wrap
  > div:nth-child(2)
  > div:nth-child(1)
  > div:nth-child(2)
  > div:nth-child(1)
  > div:nth-child(1) {
  white-space: nowrap;
}

.robosell-warning-modal-auto .am-modal-content {
  text-align: left;
}
.robosell-warning-modal-auto
  > div:nth-child(2)
  > div:nth-child(1)
  > div:nth-child(1) {
  display: none;
}
.robosell-warning-modal-auto
  > div:nth-child(2)
  > div:nth-child(2)
  .bit-sell-wrap {
  padding: 0px 0px 20px;
}
.robosell-warning-modal-auto > div:nth-child(2) > div:nth-child(1) {
  padding: 20px 20px 0px;
}
.robosell-warning-modal-auto .bit-container-padding-horizontal {
  overflow: auto;
  padding-bottom: 0;
}
.sell-change-bank-modal-wrapper .am-modal-content {
  text-align: left;
}
.bit-sell-info-pic {
  background: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 12px;
  text-align: center;
  width: 100%;
}
.bit-back-sell-wrap {
  display: flex;
  margin: 15px 0;
}
.bit-back-sell-wrap h3 {
  margin: 0 0 0 8px;
}
.bit-back-sell-close {
  display: flex;
  margin-left: -5px;
  margin-top: 1px;
}
.bit-fee-sell-info {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
  text-align: left;
  position: relative;
  padding-right: 20px;
}
.bit-fee-sell-info .red {
  background: var(--modal-surface) !important;
  color: var(--red-default);
  margin-right: 6px;
}
.bit-fee-sell-info .green {
  background: var(--modal-surface) !important;
  color: var(--green-default);
  align-items: flex-start;
  display: flex;
}
.bit-fee-sell-info img {
  height: 15px;
  margin-right: 5px;
  width: 15px;
}
.bit-fee-separator {
  background: #ededed;
  height: 1px;
  margin: 24px 0 0px -20px;
  width: calc(100% + 40px);
}
.bit-fee-sell-info div:first-child {
  max-width: auto;
  color: var(--text-primary);
}
.bit-info-icon-right {
  position: absolute;
  right: 8px;
  top: 15px;
  margin-left: 5px;
  z-index: 99;
}
.bit-margin-bottom-min-10 {
  margin-bottom: -10px;
}
.bit-margin-bottom-10 {
  margin-bottom: 10px;
}
.modal-close-flat {
  position: absolute;
  top: 21px;
  right: 18px;
  z-index: 99;
}
.bit-sell-info-pic img {
  height: 105px;
}
.bit-margin-top-min-5 {
  margin-top: -5px;
}

.bit-dashed-box-bank,
.bit-dashed-box {
  border: 1px dashed var(--modal-border);
  border-radius: 5px;
  padding: 10px 10px 18px;
  width: 100%;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
.bit-margin-vertical {
  margin: 0 0 10px;
  padding-right: 10px;
}
.bit-align-left {
  text-align: left;
}

.bit-rek-row {
  display: block;
  border-bottom: 1px solid #e4e4e4;
}
.bit-dashed-box .bit-rek-row:first-child,
.bit-dashed-box .bit-rek-row:last-child {
  border-bottom: 0;
}
.bit-dashed-box.source-bank {
  padding: 14px 16px;
}
.bank-source-card-wrapper .bank-source-card:not(:last-child) {
  margin-bottom: 16px;
}
.bit-rek-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.bit-rek-flex div:first-child {
  width: calc(40% - 25px);
}
.bit-rek-flex div:last-child {
  width: 40%;
}
.bit-rek-flex h4 {
  margin: 10px 0;
}
.bit-border-bottom-gray {
  border-bottom: 1px solid #e4e4e4;
}
.bit-rek-flex p {
  margin: 0 0 5px;
}
.bit-change-flex {
  display: flex;
  column-gap: 12px;
  width: 100%;
  align-items: center;
}
.bit-change-flex div:first-child {
  width: calc(40% - 25px);
}
.bit-change-flex div:last-child {
  width: 40%;
}
.bit-change-flex h4 {
  margin: 10px 0;
}
.bit-change-flex p {
  margin: 0 0 5px;
}

.jago-banner-container-sell.loading-data {
  filter: grayscale(1);
}
.modal-header {
  position: fixed;
  background: #fff;
  padding: 10px 0;
  display: flex;
  z-index: 9;
}
.modal-header h4 {
  margin: 0;
}
.sell-modal-bank-source-wrapper {
  position: relative;
  padding: 4px 20px 0 20px;
}
.sell-modal-bank-source-header {
  position: absolute;
  background-color: #fff;
  margin-left: -20px;
  width: 100%;
  z-index: 5;
}
.sell-modal-header-custom {
  padding: 12px 20px !important;
}

.sell-modal-bank-source-info .modal-content {
  padding-bottom: 20px !important;
}

.sell-modal-bank-source-info-header {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background: #ffff;
  padding: 0 20px;
  border-bottom: 1px solid #ededed;
  z-index: 999;
}

.robo-sell-close {
  cursor: pointer;
}
.sell-change-bank-account-wrapper {
  position: relative;
  padding: 0 20px 40px;
}

.sell-change-bank-account-wrapper .am-checkbox-wrapper:after {
  content: none;
}
.sell-change-bank-account-wrapper .am-checkbox-inner {
  top: 0;
  right: 16px;
}
.sell-change-bank-account-modal .sell-source-bank {
  border-color: var(--modal-border);
}
.sell-change-bank-account-modal .sell-bank-card {
  border-color: var(--modal-border);
}
.sell-change-bank-account-wrapper .bit-bank-change {
  padding-bottom: 0;
}
.bit-bank-change > div:last-child {
  margin-bottom: 0;
}

.sell-change-bank-account-wrapper .am-checkbox-inner::after {
  height: 6px;
  top: 3px;
  right: 5.5px;
}

.sell-change-bank-account-header {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background: #ffff;
  padding: 0 20px;
  border-bottom: 1px solid #ededed;
  z-index: 999;
}
.sell-modal-bank-source-content {
  padding: 0 0 0px 0 !important;
}
.modal-close-sumber-bank {
  position: absolute;
  top: 20px;
  right: 10px;
}
.bit-sell-modal-scroll {
  height: auto;
  max-height: calc(100vh - 64px);
}
.bit-load-sell {
  margin: -12px 0 10px !important;
}
.bit-load-sell:before,
.bit-load-single-sell:before {
  background-color: #ee4a49 !important;
}
@media (max-width: 375px) {
  .sell-change-bank-modal-wrapper .bit-change-info {
    min-width: 200px;
    width: calc(100% - 20px);
  }

  .sell-change-bank-modal-wrapper .bit-change-info .paynote-2 {
    word-break: break-word;
  }
}
@media (min-width: 341px) {
  .bit-fee-sell-info div:first-child {
    max-width: none;
  }
}
@media (min-width: 500px) {
  .bit-sell-wrap-title > h3 > br {
    display: none;
  }
}
@media (max-width: 340px) {
  .bit-fee-sell-info div:first-child {
    max-width: 180px;
  }
}

/* ---- Sell Update ---- */
.bit-bank-row {
  border-bottom: 1px solid var(--modal-divider-thin);
  margin: 0 0 12px 0;
  padding: 4px 0 7px;
  position: relative;
  width: 100%;
}
.bit-txt-blue {
  color: var(--modal-blue-default);
}

.bit-bank-flex div:last-child {
  padding-right: 25px;
}
.bit-bank-flex p {
  font-size: 13px;
  color: var(--text-primary);
}
p.bit-bank-info {
  color: var(--text-tertiary);
  font-size: 12px !important;
  margin-top: -4px !important;
}
.change-bank-arrow {
  margin-bottom: 10px;
}
.bit-bank-refresh {
  position: absolute;
  right: 0;
  top: 15%;
}
.bit-bank-refresh-animation {
  position: absolute;
  right: -7px;
  top: 9%;
}
.bit-bank-change {
  position: relative;
  height: auto;
  padding-bottom: 8px;
  margin-top: 0 !important;
}
.sell-change-bank-account-modal .bit-bank-change {
  padding-bottom: 0px;
}
.sell-change-bank-account-modal
  .bit-bank-change
  > label:last-child
  .sell-bank-card {
  margin-bottom: 0px;
}
.bit-change-row {
  border-bottom: 1px solid #ededed;
  display: flex;
  padding: 8px 18px;
  align-items: flex-start;
  text-align: left;
}
.bit-change-row .am-checkbox {
  align-self: flex-start;
  position: absolute;
  right: 18px;
}
.bit-change-info {
  align-items: center;
  display: flex;
  width: calc(100% - 20px);
}
.bit-change-info .bankName {
  display: block;
  margin-top: 0;
  word-break: break-word;
}
.bit-change-info div:first-child {
  max-width: 90px;
}
.bit-change-info .bit-flex-paynote span {
  margin-bottom: 0;
}
.bit-change-info .paynote-0 {
  align-self: flex-start;
}
.bit-change-row .am-checkbox-inner {
  border: 0;
}
.bit-change-row .am-checkbox-checked .am-checkbox-inner:before {
  display: block;
  position: absolute;
  left: 21px;
  right: -15px;
  top: 2px;
  z-index: -1;
  font-size: 12px;
}
.bit-change-row .am-checkbox-inner:after {
  top: 2.5px;
  right: 7px;
}
.bit-green-button {
  background: #00ab6b !important;
  border: 1px solid #00ab6b !important;
  color: #fff !important;
}
.bit-modal-centered {
  text-align: center;
}
.bit-modal-centered h3 {
  margin: 22px 0 8px;
}
.bit-modal-centered p {
  font-size: 14px;
  margin: 0 0 20px;
}
.bit-modal-centered img {
  margin: 20px 0 0;
  text-align: center;
}
.bit-green-rounded-btn {
  background: #00ab6b;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 40px;
  width: fit-content;
  border-radius: 30px !important;
  margin: auto;
  padding: 0 50px !important;
  font-size: 14px;
}
.bit-bank-change.index
  .bit-change-row
  .am-checkbox-checked
  .am-checkbox-inner:before {
  background: #ededed;
  border-radius: 2px;
  padding: 1px 4px;
  content: 'Default';
  color: #949494;
  display: block;
  font-weight: bold;
  position: absolute;
  left: -46px;
  right: 0;
  top: 2px;
  z-index: -1;
  font-size: 10px;
  width: 32px;
}
.bit-bank-change.index .bit-change-row .am-checkbox-inner:after {
  top: 3.5px;
  right: 7px;
}
.nopadding-right {
  padding-right: 0;
}
.bit-dashed-box div:last-child .bit-bank-row {
  border-bottom: 0;
  margin-bottom: -14px;
  margin-top: 8px;
}
@media (max-width: 330px) {
  .bit-change-info {
    width: calc(100% - 55px);
  }
}
.bit-total-reksadana {
  color: #b5b5b5;
  font-weight: normal;
  font-size: 12px;
  padding-top: 2px;
}

.sell-all {
  text-align: left;
}

.sell-all span {
  margin-left: 10px;
  font-size: 14px;
  color: var(--text-secondary);
}

.sell-all-checkbox > div:nth-child(2) {
  border: 1px solid var(--inactive);
}
.sell-all-checkbox.disabled > div:nth-child(2) > img {
  display: none;
}

.sell-all .am-checkbox {
  margin-left: 0px;
}

.sell-all a {
  font-size: 14px;
  margin-left: 15px;
  font-weight: bold;
  color: #333;
  text-decoration: none;
  outline: 0px;
}

.sell-all .am-checkbox.am-checkbox-checked .am-checkbox-inner {
  border-color: #00ab6b;
  background: #00ab6b;
}

.new-robo-sell {
  padding: 0px 20px 0px 0px;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
}

.new-robo-sell .sell-all {
  display: flex;
  width: 100%;
}

.new-robo-sell .reksadana-name-title {
  width: 100%;
  margin: 0;
  padding-left: 20px;
  text-overflow: ellipsis;
  color: var(--text-primary);
}

.sell-all p {
  font-weight: normal;
  text-align: left;
  width: 90%;
  display: inline;
  float: right;
}

.wrap-it-up {
  display: block;
  position: relative;
  width: 100%;
  border-top: 1px solid var(--modal-border);
  border-bottom: 1px solid var(--modal-border);
}

.new-robosell-btn {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 16px 20px;
  z-index: 999999;
  transform: translateZ(0);
}

.robo-sell-scroll {
  overflow-y: hidden;
}
.robo-sell-scroll + .pad20LR {
  padding-top: 15px;
}
.pad20LR {
  padding: 0 20px;
}

.sell-modal-container {
  position: relative;
}

.robosell-title {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
  padding: 4px 20px;
  font-size: 12px;
  z-index: 99;
  padding: 0 20px;
}

.robosell-title h3 {
  margin: 10px 0;
}

.new-robosell-warning {
  background-color: var(--modal-orange-low);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.new-robosell-warning svg {
  width: 24px;
  height: 24px;
  margin: 0;
  flex-shrink: 0;
}
.new-robosell-warning span + svg {
  width: 20px;
  height: 20px;
  margin: 0;
}

.new-robosell-warning span {
  color: var(--text-primary);
  text-align: left;
  font-size: 12px;
  width: calc(100% - 70px);
  margin-left: 14px;
  line-height: 135%;
}

.new-robosell-warning span > span {
  color: var(--modal-orange-default);
  font-weight: 400;
  margin-left: 0;
}

.new-robosell-warning .am-list-body {
  background-color: transparent;
}

.new-robosell-warning .am-list-item {
  background-color: transparent;
  padding: 0px;
}

.new-robosell-warning .am-list-item .am-list-line .am-list-content {
  display: inherit;
  padding: 0px;
}

.new-robosell-warning .am-list-item .am-list-line {
  padding-right: 0px !important;
}

.modal-quick-sell {
  background: #fff;
  border-radius: 5px 5px 0 0;
}

.modal-quick-sell .am-modal-content {
  background: transparent;
}

.reksadana-total-sell {
  display: inherit;
  color: var(--text-primary);
}

.robo-bold {
  font-weight: bold;
  padding-right: 5px;
}

.real-bold {
  font-weight: bold;
}

.confirmation-withdrawal-info {
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
}

.withdrawal-info-border-top {
  border-top: 1px solid var(--modal-border);
}

.confirmation-instant-redemption-unavailable-label {
  display: flex;
  background: var(--modal-cobalt-low);
  border: 1px solid var(--modal-cobalt-default);
  border-radius: 4px;
  width: 100%;
  align-items: center;
  padding: 8px 8px 8px 12px;
}

.confirmation-instant-redemption-logo-container {
  margin-right: 8px;
  display: inline-flex;
}

.perkiraan-nilai-jual {
  background-color: var(--modal-highlight);
  width: 100%;
}

.perkiraan-nilai-jual .reksadana-name-title {
  display: block;
  text-align: left;
  font-weight: bold;
}

.perkiraan-nilai-jual .reksadana-total-sell {
  display: block;
  text-align: right;
}

.bit-usd-attention {
  align-items: center;
  background: var(--modal-cobalt-low);
  border-radius: 0 0 5px 5px;
  display: flex;
  font-size: 12px;
  height: 62px;
  margin: 0 0 20px;
  padding: 0 12px 0 12px;
  text-align: left;
}
.bit-usd-attention p {
  margin-right: 8px;
  margin-left: 12px;
  line-height: 16.2px;
  color: var(--text-primary);
}
.bit-usd-attention button {
  border-radius: 20px;
  color: var(--modal-cobalt-default);
  font-size: 13px;
  margin-left: auto;
  background-color: transparent;
  border: none;
}

.loader-sell-unit {
  border: 1px solid var(--modal-border);
  padding: 15px 70px 15px 160px;
  border-radius: 5px 5px 0px 0px;
  margin-bottom: -2px;
}

@media only screen and (min-width: 1030px) {
  .modal-quick-sell {
    border-radius: 5px;
  }
  .modal-close-sumber-bank,
  .modal-close-flat,
  .bit-bank-refresh,
  .bit-info-icon-right {
    cursor: pointer;
  }
}
.robosell-warning-modal-auto .bit-container-padding-horizontal {
  padding: 0px 20px;
}

.sell-change-bank {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sell-change-bank.bit-change-info {
  align-items: flex-start;
}

.sell-change-bank .bit-flex-paynote {
  display: flex;
  flex-direction: row;
  margin-right: 16px;
  width: 100%;
  max-width: 100% !important;
  align-items: center;
  margin-bottom: 4px;
}

.sell-change-bank .bit-flex-paynote .paynote-1 {
  text-align: center;
  margin-right: 10px;
  width: 80px;
  padding: 7px 8px;
}

.sell-change-bank .paynote-0 .paynote-3 {
  font-size: 14px;
  font-weight: 600;
}

.sell-change-list {
  border: 1px solid #ededed;
  border-radius: 5px;
  margin-bottom: 10px;
}

.sell-change-list.checked {
  border: 1px solid #8dd4b6;
}

.bit-change-row.sell-change-list {
  padding: 12px;
}

.clickable-image {
  width: 25px;
  height: 20px;
  overflow: hidden;
}

.clickable-object img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}

.reksadana-left-content {
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 16px;
}

.sell-modal-confirmation-instant-logo {
  height: 20px;
  width: 20px;
  margin-left: 4px;
}
.sell-modal-confirmation-radio.unchecked > div:nth-child(2) > img {
  display: none;
}
.sell-modal-confirmation-radio.unchecked > div:nth-child(2) {
  border-color: var(--icon-default);
  margin-top: 2px;
}

.sell-modal-bank-instant-logo {
  height: 18px;
  width: 18px;
}

.sell-modal .modal-title {
  height: 15px !important;
  margin-bottom: 24px;
}

.bit-bank-change-checkbox {
  position: relative;
}

.bit-bank-change-checkbox .paynote-1 {
  width: 104px !important;
}

.bit-bank-change-checkbox > input + div {
  position: absolute;
  right: 18px;
  border: none;
}

.mr-0 {
  margin-right: 0 !important;
}

.transfer-fee-modal .info-modal-bulb {
  margin-top: 24px;
}

.transfer-fee-modal .am-modal-title h4 {
  margin-top: 32px;
  margin-bottom: 8px;
}

.transfer-fee-modal .info-modal-body {
  padding-bottom: 40px;
}

.transfer-fee-modal .modal-close-new > svg {
  stroke: #b5b5b5;
}

.sell-modal-detail .bit-container-padding-horizontal {
  padding: 0 20px !important;
}

.bank-jago-recomend {
  background-color: var(--jago-low);
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.bank-jago-recomend img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  align-self: center;
}

.bank-jago-recomend span {
  font-size: 11px;
  line-height: 135%;
  color: var(--text-primary);
}

.bank-source-card {
  padding: 14px 16px;
}

.bank-source-card .product-name {
  margin-bottom: 12px;
}
