#lottie {
  background-color: #fff;
  width: 250px;
  height: 250px;
  display: block;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  text-align: center;
  opacity: 1;
}

.satu-box {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--surface);
  height: 100vh;
}
.satu {
  width: 64px;
  height: 40px;
  background: #00ab6b;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.dua {
  width: 48px;
  height: 24px;
  background: var(--surface);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
}
.dua b {
  display: block;
  width: 8px;
  height: 8px;
  background: #00ab6b;
  animation-duration: 1s;
  position: relative;
  animation-iteration-count: infinite;
}

.dua b:nth-child(2) {
  animation-name: x1;
}

.dua b:nth-child(3) {
  animation-name: x2;
}

.tiga {
  display: block;
  width: 8px;
  height: 8px;
  background: var(--surface);
  position: absolute;
  transform: rotate(45deg);
  top: -12px;
}

@keyframes x1 {
  0% {
    transform: translate(0);
    background: #00ab6b;
  }
  25% {
    transform: translate(-60%);
  }
  50% {
    transform: translate(0);
  }
  75% {
    transform: translate(60%);
  }
  100% {
    transform: translate(0);
    background: #858585;
  }
}

@keyframes x2 {
  0% {
    transform: translate(0);
    background: #858585;
  }
  25% {
    transform: translate(-60%);
  }
  50% {
    transform: translate(0);
  }
  75% {
    transform: translate(60%);
  }
  100% {
    transform: translate(0);
    background: #00ab6b;
  }
}

.wrap-line {
  background: linear-gradient(to right, #8ae07a, #00ab6b);
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  font-size: 0;
  color: transparent;
  height: 10px;
}
.line {
  background: transparent;
  width: 100%;
}
.line + .line {
  border-left: solid 2px #fff;
}
.mimistep {
  position: relative;
  padding: 10px 0;
  margin: 20px 0;
}
.mimistep .start {
  top: 0;
  left: -5px;
}
.mimistep .end {
  top: 0;
  right: -5px;
}

.mimistep-flag {
  background: #fff;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  position: absolute;
  color: #6b6b6b;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #ededed;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.circle-icon {
  color: #fff;
  background: #00ab6b;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  margin-top: 15px;
}
.profiling-checkpoint .am-list-item {
  align-items: flex-start;
}

.profiling-checkpoint .am-list-item .am-list-content {
  color: #333;
  font-weight: bold;
  font-size: 14px;
}
.profiling-checkpoint .am-list-item .am-list-brief {
  font-size: 13px;
  margin-top: 0;
  overflow: visible;
  white-space: initial;
  font-weight: normal;
}

.profiling-checkpoint-title {
  margin: 30px 0 10px 0;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}
.profiling-checkpoint-text {
  text-align: left;
  color: #888;
}

.modal-fullpage {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.modal-full-content {
  max-height: calc(100% - 0);
  overflow: auto;
}
.modal-full-footer {
  height: 0;
  padding: 10px 20px;
}

.nantisaja {
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  color: #888;
}

@media only screen and (min-width: 1030px) {
  .satu-box {
    background: none;
    height: calc(100vh - 220px);
    position: relative;
  }
}

.secret-otp {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 5px;
  font-size: 1.2rem;
  color: white;
  background: rgba(0, 0, 0, 0.5);
}

.secret-otp.none {
  display: none !important;
}
