.modal-switch-account-wrapper {
  padding-top: 10px;
  overflow: scroll;

  /* Disable text selection and copying */
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-switch-account-wrapper .user-profile-wrapper .user-profile-image {
  background-color: var(--modal-surface);
}

.user-profile-wrapper {
  display: flex;
  align-items: center;
  padding: 20px 0px;
  position: relative;
  cursor: pointer;
}

.user-profile-wrapper:not(:last-child)::before {
  position: absolute;
  content: ' ';
  height: 1px;
  width: 100%;
  bottom: 0;
  background-color: var(--modal-divider-thin);
}

.user-profile-image,
.user-profile-image-organization {
  background: var(--modal-surface);
}

.user-profile-image {
  background: #fff;
  border-radius: 100%;
  flex-shrink: 0;
  height: 48px;
  margin-right: 15px;
  width: 48px;
}

.user-profile-image-dark {
  box-shadow: none;
}

.user-profile-image img {
  height: 100%;
  width: 100%;
}

.user-profile-detail {
  margin-right: 15px;
}

.text-user-profile-detail {
  color: var(--text-secondary);
}

.text-title-user-profile-detail {
  color: var(--text-primary);
}

.user-profile-detail p {
  word-break: break-word;
}

.user-profile-active-badge {
  margin-left: auto;
  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;
}

.add-new-institution {
  display: flex;
  align-items: center;
  padding: 20px 0px;
  cursor: pointer;
}

.add-new-institution div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  /* opacity: 0.5; */
  border: 1px solid var(--green-high);
}
