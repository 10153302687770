.loading-dialog-mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
}

.loading-dialog {
  position: fixed;
  background-color: white;
  top: 50%;
  left: 50%;
  text-align: center;
  min-width: 250px;
  padding: 24px;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  align-items: center;
}

.loading-dialog.no-message {
  min-width: initial;
}

.loading-icon {
  animation: loadingIconRotate 1s infinite linear;
  width: 48px;
  height: 48px;
  margin: 0 auto;
  text-align: center;
  transform-origin: center center;
}

/* The animation rotate for loading icon*/
@keyframes loadingIconRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
