.bit-container-padding-horizontal .profilling-select-list-padding {
  padding-bottom: 215px;
}

@media (min-width: 1030px) {
  .profilling-select-list-padding {
    padding-bottom: 165px !important;
  }
  .modal-content   .profilling-select-list-padding {
    padding-bottom: 85px !important;
  }
  .top-label-rd-wrapper {
    position: relative !important;
    width: 35px !important;
    margin-left: 16px !important;
  }
  .top-label-rd {
    border-radius: 50px !important;
    margin: auto auto !important;
  }
}

.top-label-rd-wrapper {
  width: 30px;
  height: 20px;
  margin-left: 6px;
}
.top-label-rd {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.bit-container-padding-horizontal .tooltip-pencairan-instan {
  display: flex;
  position: absolute;
  top: -47px;
  right: -13px;
  background-color: var(--green-default);
  border-radius: 4px;
  padding: 8px 12px;
  color: white;
  white-space: nowrap;
}
.compare-product-modal-body .am-card.with-footer .porto-card {
  border: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.compare-product-modal-body .am-card.with-footer {
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.compare-product-modal-body .am-card {
  border: 1px solid var(--modal-border) !important;
}

.bit-container-padding-horizontal .tooltip-pencairan-instan::after {
  content: '';
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 7px 10px 7px 0;
  border-color: transparent var(--green-default) transparent transparent;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  position: absolute;
  transform: translate(221px, -18px) rotate(270deg);
  bottom: -27px;
  right: 236px;
}

.bit-container-padding-horizontal .tooltip-top-company-exist {
  display: flex;
  position: absolute;
  right: 36px;
  top: -30px;
  background-color: #00ab6b;
  border-radius: 4px;
  padding: 8px 12px;
  color: white;
}

.bit-container-padding-horizontal .empty-state-category-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 50vh;
}

.empty-state-category-page-subtitle {
  width: 100%;
  text-align: center;
}

.bit-fixed-button-modal-wrapper-2 {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 20px;
  background: #fff;
  border-top: 1px solid #ededed;
  opacity: 1;
  z-index: 10;
  left: 0px;
}
