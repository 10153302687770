.percentage svg {
  margin-right: 1px;
  display: none;
}

.percentage-green > div {
  color: #00ab6b;
}
.percentage-red > svg,
.percentage-green > svg {
  display: block;
}

.percentage-green > svg {
  transform: rotate(-45deg);
  stroke: #00ab6b;
}

.percentage-red > div {
  color: #ee4a49;
}

.percentage-red > svg {
  transform: rotate(45deg);
  stroke: #ee4a49;
}

.percentage-format > div {
  display: inline-block;
  vertical-align: middle;
}
.percentage-format .percentage-zero + div{
  color: var(--text-primary) ;
}
