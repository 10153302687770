.button-action {
  display: flex;
  margin-bottom: 20px;
}

.button-reset {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00ab6b !important;
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  width: 50%;
  margin-right: 5px;
}

:global(html:not([data-scale])) .button-reset:global.am-button::before {
  border: 2px solid var(--modal-border);
}

.button-apply {
  width: 50%;
  margin-left: 5px;
  font-weight: 700;
}

.bit-new-sorting-title {
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 12px;
  color: var(--text-primary);
}

.bit-new-sorting {
  margin: 0 20px;
  padding: 0px 0 12px;
  text-align: left;
}
.bit-new-sorting:last-child{
  padding: 0px !important;
}

.bit-new-sorting:first-child {
  padding-top: 0px;
}

.bit-new-sorting:first-child .bit-sorting-items {
  margin-top: 0;
}

.bit-sorting-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.bit-sorting-items.bit-order {
  margin: 0;
  flex-direction: column;
}
.bit-sorting-items.bit-sorting div {
  border: 1px solid var(--modal-border);
  color: var(--text-primary);
  border-radius: 15px;
  flex: 0 1;
  flex-basis: auto;
  margin: 0 8px 14px 0px;
  padding: 6px 16px;
  text-align: center;
  align-self: center;
  font-size: 12px;
}

.bit-sorting-items.bit-order div input[type='radio'] {
  display: none;
}
.bit-sorting-items.bit-order div input[type='radio'] + img {
  width: 18px;
  height: 18px;
  border: 1.5px solid var(--inactive);
  border-radius: 100%;
  margin-right: 5px;
}
.bit-sorting-items.bit-order div input[type='radio'] + img.bit-filter-checked {
  border: none;
}
.bit-sorting-items.bit-periode div {
  border: 1px solid var(--modal-border);
  color: var(--text-primary);
  border-radius: 15px;
  flex: 0 1;
  flex-basis: auto;
  margin: 0 8px 14px 0px;
  padding: 6px 16px;
  text-align: center;
  align-self: center;
  font-size: 12px;
}

.bit-new-sorting div.bit-filter-selected {
  background-color: transparent;
  color: #00ab6b;
  font-weight: 700;
  border: solid 1px #00ab6b;
  
}

.bit-sorting-items.bit-order div {
  color: var(--text-primary);
  margin: 5px 8px;
  display: flex;
  align-items: center;
}

.bit-sort-modal-body {
  padding-bottom: 0;
}

.bit-sort-modal-body :global(.bit-modal-header) {
  margin-bottom: 18px;
}

.bit-sort-modal-content {
  overflow-y: auto;
}
