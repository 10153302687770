.bit-section-title {
  align-items: center;
  display: flex;
  max-height: 100px;
  padding: 16px 20px;
  justify-content: space-between;
}

.bit-section-title .bit-title {
  align-items: center;
  display: flex;
}

.bit-section-title .bit-title svg {
  color: #00ab6b;
  height: 24px;
  margin-right: 6px;
  width: 24px;
}

.bit-section-title .bit-title p {
  font-weight: bold;
}
