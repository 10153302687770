.wrapper {
  display: flex;
  background: var(--surface);
  border: 1px solid var(--border);
  box-sizing: border-box;
  border-radius: 6px;
  align-items: center;
  padding: 8px 12px 8px 8px;
  margin: 20px 0 20px 0;
}

.wrapper > .icon {
  margin-right: 12px;
  width: 72px;
  height: 72px;
}

.icon > picture {
  display: flex;
}

.category-details > p {
  margin-top: 4px;
}

:global(.modal-choose-reksadana)  .wrapper{
  background: var(--modal-surface);
  border: 1px solid var(--modal-border);
}
:global(.modal-choose-reksadana) >div:nth-child(3) :global .bit-filter-result-row >div{
  background: var(--modal-surface);
  border-color: var(--modal-border);
}
:global(.modal-choose-reksadana) >div:nth-child(3) :global .instant-redemption-footer-card{
  background-color: var(--modal-green-low);
}

@media only screen and (min-width: 1030px) {
  .wrapper {
    margin: 20px 0px;
  }
}
