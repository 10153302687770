.content-text {
  text-align: center;
}

.button-create {
  margin-top: 20px;
}

.spot-hero {
  width: 180px;
  margin-bottom: 20px;
}
