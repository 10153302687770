.bit-filter-rd :global .bit-modal-body {
  padding: 20px 20px 32px 20px;
}

.fund_management_type,
.redemption_type {
  margin-bottom: 24px;
}

.fund_management_type__title,
.redemption_type__title,
.custodian_bank__title {
  font-weight: 700;
  font-size: 13px;
  line-height: 135%;
  margin-bottom: 12px;
  color: var(--text-primary);
}

.fund_management_type__chips,
.redemption_type__chips {
  display: flex;
}
.filter-reksa-footer >div:nth-child(1) >button >span{
  font-size: 14px;
}
.custodian_bank__button.selected button{
  border-color: var(--green-default);
  color: var(--green-default);
  font-weight: 700;
}

.custodian_bank__button button {
  display: flex;
  justify-content: space-between;
  padding: 14px 16px;
  background: var(--surface-odal);
  border: 1px solid var(--modal-border);
  border-radius: 50px;
  color: var(--text-primary);
  width: fit-content;
  height: 28px;
}
.custodian_bank__button button >svg:nth-child(2){
  margin-left: 4px;
}

.custodian_bank__button__left_content {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.custodian_bank__button__left_content img {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.chip {
  padding: 6px 16px;
  border: 1px solid var(--modal-border);
  color: var(--text-primary);
  border-radius: 15px;
  margin-right: 8px;
  font-size: 12px;
  line-height: 135%;
  height: 28px;
}

.chip__selected {
  font-weight: 700;
  color: #00ab6b;
  border-color: #00ab6b;
}

.bit-filter-rd__action_button {
  display: flex;
  justify-content: space-between;
}

.bit-filter-rd__action_button button:nth-child(1) {
  border: 1px solid var(--modal-border);
  margin-right: 6px;
  font-weight: 700;
}

.bit-filter-rd__action_button button:nth-child(2) {
  margin-left: 6px;
  font-weight: 700;
  padding: 12px 14px;
}

@media only screen and (max-width: 360px) {
  .filter-btn-primary{
    width: 70% !important;
  }
  .filter-btn-primary >span{
    font-size: 12px !important;
  }
  .filter-btn-secondary >span{
    font-size: 12px !important;
  }
}
