.bit-choose-bank :global .bit-modal-body {
  padding: 20px 20px 32px 20px;
  max-height: calc(100% - 45px);
}

.bit-choose-bank :global .bit-modal-header .bit-title-text h3 {
  text-align: center;
}
.bank-options__card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid var(--modal-divider-thin);
}

.bank-options__card:last-child {
  border-bottom: none;
}

.bank-options__card :global label {
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
}
.bank-options__card :global label div svg path {
  stroke: #fff;
}
.bank-options__card :global label div:last-child {
  padding-left: 0;
}

.bank-options__card__content_left {
  display: flex;
  align-items: center;
}

.bank-options__card__content_left img {
  margin-right: 16px;
  width: 40px;
  height: 40px;
}

.bank-options__card__content_left span {
  font-weight: 700;
}

.bit-choose-rd__action_button button {
  margin-top: 20px;
  font-weight: 700;
}
