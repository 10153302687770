.get-started-page-reksadana .porto-card {
  background: var(--surface);
}

.choose-product-modal-body .porto-card {
  background: var(--modal-surface);
}

.porto-card {
  background: var(--surface);
  border-radius: 4px;
}
.modal-choose-reksadana .porto-card {
  background: var(--modal-surface);
}
.bit-modal .porto-card {
  background: var(--modal-surface);
}
.bit-add-rec-btn {
  border: 1px solid var(--green-default);
  border-radius: 5px;
  color: var(--green-default);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 20px;
  font-size: 14px;
}
.home-portfolio {
  padding: 20px 20px 70px 20px;
  position: relative;
}
.home-portfolio-wrap {
  border-bottom: solid 1px #ededed;
  border-top: 0;
  background: #fff;
}
.bit-reksa-wrap + .bit-reksa-wrap {
  border-top: 0;
}

/* Molecules.css */
.molecules-wrap {
  border-bottom: solid 1px #ededed;
  border-top: solid 1px #ededed;
  margin: 10px;
  padding: 12px 0;
}
.bit-modal-header {
  display: flex;
  align-items: flex-start;
  position: relative;
}

.bit-modal-back {
  display: flex;
  height: 20px;
  justify-content: flex-start;
  margin-top: 4px;
  width: 20px;
}
.bit-modal-close {
  display: flex;
  height: 20px;
  justify-content: flex-end;
  margin-top: 4px;
  width: 20px;
}
.bit-modal-center {
  display: flex;
  flex: 1 1;
  margin: 0 12px;
}
.bit-title-text {
  width: 100%;
}
.bit-title-text.center {
  text-align: center;
}
.bit-title-text h3 {
  margin: 4px 0;
  font-size: 14px;
}
.bit-modal-header div:first-child .bit-title-text.center {
  transform: translateX(10px);
}
.bit-reksa-item {
  display: flex;
  flex: 1;
}
.bit-reksa-item h3 {
  font-size: 14px;
  margin: 4px 0;
  color: var(--text-primary);
}
.bit-reksa-item p {
  color: var(--text-primary);
  font-size: 12px;
}
.bit-reksa-item div:last-child {
  flex: 1;
}
.bit-reksa-item .bit-reksa-label {
  display: flex;
  align-items: center;
  color: var(--text-primary);
}
.bit-reksa-pic {
  background: #ccc;
  border-radius: 50%;
  height: 34px;
  margin-right: 12px;
  margin-top: 5px;
  overflow: hidden;
  position: relative;
  width: 34px;
}
.bit-reksa-pic img {
  width: 100%;
}

/* Recurring.css */
.bit-recurring-product {
  align-items: center;
  display: flex;
  font-size: 13px;
  padding: 0px 0px 15px 0px;
}
.bit-recurring-product img {
  height: 30px;
  margin-right: 10px;
  width: 30px;
}
.bit-recurring-product div:nth-child(2) {
  font-weight: bold;
  flex: 1;
  margin-right: 32px;
  margin-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--text-primary);
}
.bit-recurring-product div:last-child {
  color: #00ab6b;
  margin-left: auto;
}

.bit-recurring-product-box {
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.product-box-recurring {
  position: relative;
  overflow: hidden;
}

.bit-recurring-product-box.recurring-robo .recurring-product-row {
  border: 1px solid var(--border);
}

.productSwitch {
  padding-top: 30px;
}
.bit-navbar-product-switch {
  background-color: var(--surface);
}

@media screen and (min-width: 1030px) {
  .bit-navbar-product-switch {
    background-color: transparent;
  }
}
.productSwitch div.portfolio-name {
  color: var(--text-primary);
}
.bit-recurring-product-box .recurring-product-row:first-child {
  border-top: solid 1px var(--border) !important;
}
.bit-recurring-product-box .recurring-product-row:nth-child(2) {
  border-top: 0;
}
.bit-recurring-product-box .recurring-product-row:last-child {
  border-top: 0;
}

.recurring .recurring-product-row {
  border-radius: 5px;
}

.product-recurring {
  border-bottom: solid 1px var(--modal-border);
  display: flex;
  height: 60px;
  padding: 0 10px 0 16px;
  position: relative;
  align-items: center;
  border-top-left-radius: 4px;
}

.product-recurring img {
  height: 35px;
  margin-right: 14px;
  width: 35px;
}

.product-recurring h3 {
  color: var(--text-primary);
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.product-recurring p {
  color: var(--text-secondary);
  font-size: 12px;
  text-align: left;
}

.product-recurring > div:nth-child(2) {
  width: calc(100% - 112px);
}
.product-recurring div:last-child {
  margin-left: auto;
}

.recurring-product-row {
  align-items: center;
  border: solid 1px var(--border);
  display: flex;
  height: 60px;
  padding: 0 10px 0 16px;
  position: relative;
}
.recurring-product-row img {
  height: 35px;
  margin-right: 14px;
  width: 35px;
}
.recurring-product-row h3 {
  color: var(--text-primary);
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
}
.card-selected .porto-card .moreBullet {
  display: none;
}
.recurring-product-row p {
  color: #b5b5b5;
  font-size: 12px;
  text-align: left;
}
.recurring-product-row > div:nth-child(2) {
  width: calc(100% - 112px);
}
.recurring-product-row div:last-child {
  margin-left: auto;
}
.recurring-pending-status {
  padding: 3px 8px;
  border-radius: 20px;
  background: #f2faff;
  border: 1px solid #aad5ee;
  margin-left: 4px;
  color: #2a90cb;
}

.get-started-page .porto-card-top {
  border-bottom: solid 1px var(--border);
  padding: 10px;
  margin-bottom: 10px;
}
.get-started-page .puller-box {
  background: var(--surface);
}
.choose-product-modal-body .get-started-page .puller-box {
  background: var(--modal-surface);
}
.get-started-page-reksadana .puller-box {
  height: 100vh;
}
.porto-card-btm,
.porto-card-top {
  display: flex;
}
.bit-porto-card-top {
  border-bottom: solid 1px #ededed;
  display: flex;
  align-items: center;
  font-size: 17px;
  padding: 9px 15px;
}
.bit-porto-card-top a {
  color: #00ab6b;
}
.porto-card-logo {
  margin-right: 15px;
  width: 34px;
  height: 34px;
}
.porto-card-logo img {
  border-radius: 50px;
  display: block;
  overflow: hidden;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  width: 34px;
}
.porto-card-name {
  width: calc(100% - 49px);
  text-align: left;
}

.porto-card-name .porto-card-product-name {
  display: block !important;
  -webkit-line-clamp: unset !important;
  -webkit-box-orient: unset !important;
  overflow: visible !important;
}

.porto-card-name > div:nth-child(1) {
  font-weight: bold;
  font-size: 15px;
  white-space: pre-line;
  margin-right: 28px;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--text-primary);
}

.porto-card-name.porto-best > div:nth-child(1) {
  margin-right: unset !important;
}

.am-card.card-selected {
  border: solid 1px var(--green-default) !important;
}
@media only screen and (min-width: 1030px) {
  .porto-card-name > div:nth-child(1) {
    margin-right: 0px;
  }
}

.sbn-order-detail-product-name {
  font-weight: bold;
  font-size: 15px;
  white-space: pre-line;
  margin-right: 28px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.porto-card-name > div:nth-child(2) {
  color: var(--text-secondary);
  padding-top: 3px;
  font-size: 13px;
}
.porto-card-name > div.difcolor {
  color: #639fca;
}
.porto-card-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 12px;
}
.porto-card-detail-text-wrapper {
  display: inline-block;
}
.porto-card-title {
  font-weight: bold;
  font-size: 15px;
  white-space: pre-line;
  color: var(--text-primary);
}

.porto-card-category {
  color: #b5b5b5;
  font-size: 13px;
}

.porto-card-btm-child:nth-child(1) {
  text-align: left;
}
.porto-card-btm-child:nth-child(2) {
  text-align: center;
}
.porto-card-btm-child:nth-child(3) {
  text-align: right;
}

.porto-card-btm > div {
  color: var(--text-primary);
  width: calc(100% / 3);
}

.porto-card-btm-child > div:nth-child(1) {
  color: var(--text-tertiary);
  font-size: 13px;
  padding-bottom: 5px;
}

.recurring-opt-row {
  align-items: center;
  display: flex !important;
  justify-content: space-between;
}
.recurring-checkbox-wrapper,
.payment-checkbox-wrapper {
  flex-direction: row;
}
.payment-checkbox-wrapper .bit-rec-flex > div:nth-child(1) {
  color: var(--text-primary);
}
.recurring-checkbox-wrapper.not-active > div:nth-child(2) > img {
  display: none;
}
.recurring-opt-row span:first-child,
.recurring-checkbox-wrapper > input + div,
.payment-checkbox-wrapper > input + div {
  order: 2;
}
.recurring-opt-row .am-checkbox-inner,
.recurring-checkbox-wrapper > input + div {
  border: none;
  top: 0px;
  border: 1.5px solid var(--inactive);
}
.recurring-checkbox-wrapper > input + div + div,
.payment-checkbox-wrapper > input + div + div {
  opacity: 1 !important;
  padding-left: 0;
  width: 100%;
}
.recurring-checkbox-wrapper-disabled > input + div,
.payment-checkbox-wrapper-disabled > input + div {
  display: none;
}
.payment-checkbox-wrapper > input + div {
  border: 1.5px solid var(--inactive);
}
.recurring-opt-row .am-checkbox-inner:after {
  top: 3px;
  right: 6px;
}
.recurring-payment-method label {
  border-bottom: 1px solid var(--modal-border);
  margin: 0 0 -1px !important;
  padding: 12px !important;
  min-height: 44px;
}
.recurring-payment-method > div {
  border-bottom: 1px solid var(--modal-border);
  margin: 0 0 -1px !important;
  min-height: 44px;
}
.bit-transfer-bank > label > div > img {
  display: none;
}
.bit-transfer-bank.selected > label > div > img {
  display: block;
}
.recurring-payment-method label:last-child {
  border-bottom: 0;
}
.recurring-payment-method div img {
  display: flex;
}
.payment-checkbox-wrapper > input + div > img {
  display: none;
}
.recurring-payment-method.selected > label > div > img {
  display: block;
}
.bit-rec-flex {
  align-items: center;
  display: flex;
}
.bit-recurring-gopay {
  width: 68px;
}
.bit-recurring-permata {
  margin-right: 6px;
  width: 24px;
}
.bit-recurring-payment-method {
  margin-right: 6px;
  width: 24px;
  height: 24px;
  margin-top: auto;
  margin-bottom: auto;
}
.bit-recurring-bca {
  margin-right: 6px;
  width: 49px;
}
.recurring-payment-method {
  border-radius: 5px;
  border: 1px solid var(--border);
  margin-bottom: 12px;
}

.payment-method-scroll .recurring-payment-method {
  border-color: var(--modal-border);
}
.recurring-payment-method div img {
  display: flex;
  margin-right: 12px;
}
.bit-other-bank-pic {
  margin-bottom: -4px;
  margin-left: -12px;
  margin-right: -2px;
  margin-top: -4px;
  width: 50px;
}
.select-recurring-portfolio {
  align-items: center;
  border-radius: 5px;
  border: solid 1px var(--border);
  display: flex;
  justify-content: space-between;
  height: 40px;
  margin: 2px 0;
  padding: 0 8px 0 14px;
}

.select-recurring-portfolio > div {
  color: var(--text-primary);
}

.select-recurring-portfolio.disabled {
  cursor: not-allowed;
  color: #e4e4e4;
}
.select-recurring-portfolio .spinner {
  margin: auto;
  height: 20px;
  width: 20px;
}
.recurring-portfolio-opt {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  max-height: calc(100vh - 60px);
  overflow: auto;
  padding-bottom: 20px;
}
.recurring-portfolio-opt label {
  width: 33%;
}
.modal-back-new {
  background: var(--modal-surface);
  position: absolute;
  left: 16px;
  padding: 15px 0 0;
  text-align: left;
  top: 0;
  width: auto;
}
.modal-back-new + div {
  margin-top: 50px;
}
.modal-back-new + div > h3 {
  background: #fff;
  left: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  padding: 14px 0 10px;
}
.modal-buy-single-product .modal-back-new {
  z-index: 110;
}
.modal-buy-single-product .porto-card-top {
  border-bottom: solid 1px #ededed;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.bit-stay-header-modal-close {
  left: 0;
  position: relative;
  width: auto;
}

/* -------- Goal Setting -------- */
.bit-modal-goal-wrapper .am-modal-body {
  max-height: 100vh;
  overflow: auto;
}
.bit-modal-goal-wrapper .am-modal-content {
  background-color: var(--modal-surface);
}
.bit-modal-goal {
  padding: 0px 14px;
}
.on-boarding-bottom {
  width: 100%;
}
.text-bold {
  font-weight: bold;
}
.bit-porto-goal .goalInput {
  color: var(--text-primary);
  border-bottom: 1px solid var(--modal-border);
}
.bit-porto-goal .am-list-item {
  background-color: var(--modal-surface);
  border: 1px solid var(--modal-border);
}
.bit-porto-goal
  .am-list-item
  .am-list-line
  .am-input-control
  > input::placeholder {
  color: var(--text-tertiary);
}
.bit-porto-goal .bit-porto-form > input::placeholder {
  color: var(--text-tertiary);

  font-weight: 700;
}
.bit-porto-goal {
  background: var(--modal-surface);
  padding: 22px 20px 18px;
  position: relative;
  margin-left: -14px;
  width: calc(100% + 28px);
  z-index: 10;
}
.bit-porto-goal-circle.green > img {
  background: #00ab6b;
  border: solid 2px #00ab6b;
  border-radius: 50px;
}
.bit-porto-goal-circle > img {
  width: 52px;
}
.bit-porto-goal > div:nth-child(10) {
  margin-bottom: 0;
}
.bit-porto-goal > div:nth-child(11) {
  margin-bottom: 0;
}
.bit-porto-goal > div:nth-child(12) {
  margin-bottom: 0;
}
.bit-modal-goal .bit-porto-goal {
  padding: 0px 0px 0px !important;
}
.bit-create-modal-goal-wrapper > div:nth-child(2) > div:nth-child(1) {
  background-color: var(--modal-surface);
}
.bit-create-modal-goal-wrapper > div:nth-child(2) > div:nth-child(3) {
  background-color: var(--modal-surface);
}
.bit-create-modal-goal-wrapper > div:nth-child(2) {
  background-color: var(--modal-surface);
}
.bit-create-modal-goal-wrapper
  > div:nth-child(2)
  .modal-footer-dark
  > div
  .am-button-disabled {
  opacity: 0.5 !important;
}

.bit-modal-goal .bit-modal-header {
  padding: 0 6px;
}
.bit-porto-goal.flex {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.bit-porto-goal > div {
  margin-bottom: 24px;
  width: calc(100% / 3);
  text-align: center;
}
.bit-porto-goal p {
  font-size: 14px;
  color: var(--text-primary);
}
.bit-porto-goal-bottom {
  background: var(--modal-surface);
  padding: 24px 18px 18px;
  margin-left: -14px;
  position: relative;
  width: calc(100% + 28px);
  z-index: 1;
}

.bit-porto-goal .bit-porto-form input[type='text'].goalInput {
  color: var(--text-primary) !important;
  -webkit-text-fill-color: var(--text-primary);
  opacity: 1;
  border-bottom: 1px solid var(--modal-border);
}
.bit-porto-goal .bit-porto-form input[type='text']:disabled.goalInput {
  color: #000 !important;
  -webkit-text-fill-color: #000;
  opacity: 1;
}
.bit-porto-goal .am-list-item .am-list-line .am-list-content {
  padding-top: 0;
  padding-bottom: 0;
}
.bit-green-new-porto {
  color: #00ab6b;
  font-weight: normal;
}
.bit-porto-form {
  width: 100% !important;
  margin-bottom: 0 !important;
}

.bit-create-porto-onboarding {
  max-height: 230px;
}

.bit-porto-form label {
  display: block;
  font-weight: bold;
  color: var(--text-primary);
}

.bit-porto-goal-institution {
  padding-bottom: 0;
  border-bottom: none;
  box-shadow: none;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.bit-porto-form-counter {
  margin-bottom: 18px;
}
.bit-porto-form .am-list-item {
  border: none;
  border-bottom: 1px solid var(--modal-border);
  padding: 10px 0 8px !important;
  height: auto !important;
  margin-bottom: 6px;
  min-height: 40px;
  background-color: var(--modal-surface);
}
.am-list-item .am-input-control input {
  color: var(--text-primary);
}
.am-list-item .am-input-control input::placeholder {
  color: var(--text-tertiary);
}
.am-list-item .am-list-line .am-list-content {
  color: var(--text-primary);
}
.bit-porto-form .bit-porto-form-counter {
  position: relative;
}
.bit-porto-form-counter .am-list-line {
  padding-right: 48px;
}
.bit-porto-form .bit-porto-form-counter-label {
  position: absolute;
  top: 10px;
  right: 0;
}
.bit-porto-form-counter .am-list-item:not(:last-child) .am-list-line {
  border-bottom: none;
}
.bit-select-porto {
  background-position: calc(100% - 12px) center;
  border-bottom: 1px solid #ededed;
  border-radius: 2px;
  font-size: 15px;
  margin: 0 0 14px;
  padding: 0;
}
select.bit-select-porto:focus {
  font-size: 15px !important;
}
.bit-porto-circle-opt {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 35px;
  justify-content: center;
  position: absolute;
  left: 20px;
  top: 45px;
  width: 35px;
}
.bit-porto-circle-opt img {
  width: 100%;
  height: auto;
}
.bit-porto-circle-category {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 36px;
  justify-content: center;
  margin-right: 16px;
  overflow: hidden;
  width: 36px;
}
.bit-porto-circle-category img {
  width: 100%;
}
.bit-porto-goal-bottom .bit-porto-twin-btn {
  display: flex;
}
.bit-porto-goal-bottom .bit-porto-twin-btn .delete {
  color: #000;
  background: #fff;
  font-size: 16px;
  width: 100%;
}
.bit-porto-goal-bottom .bit-porto-twin-btn .save {
  color: #fff;
  background: #00ab6b;
  font-size: 16px;
  width: 100%;
}
.bit-porto-twin-btn a.am-button:first-child + .save {
  margin-left: 16px;
}
.bit-porto-goal-bottom .cust-twins .am-button:first-child {
  background: #fff;
  color: #000;
  font-size: 16px;
}
.bit-porto-goal-bottom .cust-twins .am-button:last-child {
  background: #00ab6b;
  color: #fff;
  font-size: 16px;
}
.bit-white-full-btn {
  background: #fff !important;
  border: 1px solid #e4e4e4 !important;
  color: #00ab6b !important;
}
.bit-porto-flex {
  align-items: center;
  display: flex;
}
.bit-porto-target {
  margin-bottom: 16px !important;
  width: 100% !important;
}
.bit-porto-goal-empty .secondary-btn {
  margin-top: 10px;
}
.bit-porto-goal-empty .recurringBoxPorto {
  border: 0;
  display: inline-flex;
  margin-bottom: 10px;
}

.recurring-button-wrapper {
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.bit-goal-step {
  display: flex;
}
.bit-goal-step .circle {
  background: #00ab6b;
  border-radius: 50%;
  height: 8px;
  justify-content: center;
  margin-right: 8px;
  transform: translate(-5px, 4px);
  width: 9.08px;
  -webkit-box-shadow: 0px 0px 0px 4px rgba(224, 247, 238, 1);
  -moz-box-shadow: 0px 0px 0px 4px rgba(224, 247, 238, 1);
  box-shadow: 0px 0px 0px 4px rgba(224, 247, 238, 1);
}
.bit-goal-step .bit-target-line {
  background: #e4e4e4;
  min-height: 70px;
  margin-left: 20px;
  width: 1px;
}
.bit-goal-step .bit-target-line-none {
  background: #fff;
  min-height: 50px;
  margin-left: 20px;
  width: 1px;
}
.bit-target-desc {
  width: 100%;
}
.bit-target-desc label {
  display: block;
}
.bit-target-desc p {
  font-weight: bold;
  font-size: 16px;
  margin: 8px 0;
}
.bit-target-separator {
  background: #ededed;
  height: 1px;
  margin-top: 12px;
  width: 100%;
  display: none;
}

.bit-track-porto-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.bit-track-porto {
  background: var(--surface);
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.bit-track-porto.bit-bg-transparent {
  background: transparent;
}
.portfolio-wrapper .bit-track-porto {
  margin-bottom: 15px;
}
.bit-track-porto-btn {
  position: relative;
  margin: 10px 0;
}

.bit-track-porto-btn p {
  white-space: nowrap;
  font-size: 11px;
}

.bit-recurring-info {
  padding: 16px;
  border: 1px solid #ededed;
  border-radius: 5px;
}

.bit-recurring-info p {
  font-size: 11px;
}

.bit-recurring-info > .bit-recurring-info-header {
  display: flex;
  align-items: center;
}

.bit-recurring-info .bit-recurring-info-icon {
  display: block;
  margin: 0;
  width: 55px;
  height: 50px;
  margin-right: 10px;
}

.bit-recurring-data {
  padding: 16px 12px;
  border-radius: 5px;
  border: solid 1px #80d5b5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0;
}

.bit-recurring-data img {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  display: block;
}

.bit-recurring-data .bit-recurring-data-left {
  display: flex;
  align-items: center;
}

.bit-recurring-data p {
  font-size: 13px;
}

.bit-recurring-data .bit-recurring-data-schedulle {
  color: #949494;
}

@keyframes hide-track-loader {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.bit-track-porto > div.bit-track-porto-btn--dark {
  background: transparent !important;
}
.bit-track-porto > div.bit-track-porto-btn {
  align-items: center;
  border: 1px solid var(--border);
  border-radius: 20px;
  color: #00ab6b;
  display: flex;
  font-weight: bold;
  padding: 2.5px 6px;
  background: var(--surface);
  height: 22px;
}
.bit-track-porto > .bit-track-porto-btn div {
  background: #00ab6b;
  border-radius: 50%;
  height: 14px;
  margin-right: 4px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bit-track-porto > .bit-track-porto-btn.offtrack div {
  background: #ee4a49;
  border-radius: 50%;
  height: 14px;
  margin-right: 4px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bit-track-porto > .bit-track-porto-btn.offtrack p {
  color: var(--red-default);
}
.bit-track-porto > .bit-track-porto-btn--dark.offtrack {
  border: 1px solid var(--red-default) !important;
}
.bit-track-porto > .bit-track-porto-btn--dark.offtrack p {
  color: var(--red-default);
}
.bit-track-porto > .bit-track-porto-btn--dark.offtrack div {
  background: transparent;
}
.bit-track-porto > .bit-track-porto-btn--dark.offtrack div img {
  background-color: var(
    --red-default
  ); /* defines the background color of the image */
  mask: url(assets/images/portofolio-asset/porto-thumb.svg) no-repeat center /
    contain;
  -webkit-mask: url(assets/images/portofolio-asset/porto-thumb.svg) no-repeat
    center / contain;
  padding-top: 15px !important;
  width: 8px !important;
  height: 8px;
  top: 1px;
  position: relative;
}

.bit-track-porto > .bit-track-porto-btn--dark.ontrack,
.bit-track-porto > .bit-track-porto-btn--dark {
  border: 1px solid #00ab6b !important;
}
.bit-track-porto > .bit-track-porto-btn--dark.ontrack div,
.bit-track-porto > .bit-track-porto-btn--dark div {
  background: transparent;
}
.bit-track-porto > .bit-track-porto-btn--dark.ontrack div img,
.bit-track-porto > .bit-track-porto-btn--dark div img {
  background-color: #00ab6b; /* defines the background color of the image */
  mask: url(assets/images/portofolio-asset/porto-thumb.svg) no-repeat center /
    contain;
  -webkit-mask: url(assets/images/portofolio-asset/porto-thumb.svg) no-repeat
    center / contain;
  padding-top: 15px !important;
  width: 8px !important;
  height: 8px;
}

.bit-track-porto > .bit-track-porto-btn--dark.achieved {
  border: 1px solid #00ab6b !important;
}
.bit-track-porto > .bit-track-porto-btn--dark.achieved div {
  background: transparent;
}
.bit-track-porto > .bit-track-porto-btn--dark.achieved div img {
  background-color: #00ab6b; /* defines the background color of the image */
  mask: url(assets/images/portofolio-asset/flagFinish.svg) no-repeat center /
    contain;
  -webkit-mask: url(assets/images/portofolio-asset/flagFinish.svg) no-repeat
    center / contain;
  padding-top: 15px !important;
  width: 9px !important;
  height: 9px;
  position: relative;
  top: 1px;
}

.bit-track-porto > .bit-track-porto-btn div img {
  transform: rotate(180deg);
  width: 7px;
  margin-top: -1px;
}
.bit-track-porto > .bit-track-porto-btn.offtrack div img,
.bit-track-porto-btn.achieved div img {
  transform: rotate(0);
  width: 8px;
  padding-top: 3px;
}
.bit-track-porto > .bit-track-porto-btn div img[alt='finish'] {
  transform: rotate(0);
}
.bit-track-porto + p {
  font-size: 13px;
}
.bit-edit-btn {
  color: var(--green-default);
  font-size: 14px;
}
.bit-category-title {
  align-items: center;
  display: flex !important;
  transform: translateY(-0.5px);
}
.bit-category-title-portofolio-detail p {
  line-height: 1.24;
}
.bit-category-title-picture {
  align-items: center;
  display: flex;
  height: 20px;
  width: 20px;
  margin-right: 8px;
}
.bit-category-title-picture img {
  width: 100%;
}
.bit-category-icon {
  background: #00ab6b;
  border-radius: 50%;
  height: 17px;
  margin-right: 6px;
  padding: 3px;
  width: 17px;
}
.bit-porto-goal-top-holder {
  display: block;
}
.bit-porto-goal-top {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 40px;
  color: var(--text-primary);
}
.bit-porto-goal-top div:last-child {
  text-align: right;
}
.bit-porto-goal-top div label {
  font-size: 13px;
  display: inline-block;
  margin-bottom: 5px;
  color: #b5b5b5;
}
.bit-porto-goal-top div p {
  font-size: 16px;
  font-weight: bold;
}

.porto-goal-top > div:nth-child(1) {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.porto-goal-top img {
  width: 30px;
  margin-right: 10px;
  display: block;
}
.bit-porto-goal-indicator {
  margin-top: 52px;
  padding: 20px;
}
.bit-porto-goal-number {
  align-items: center;
  border-bottom: 1px solid #ededed;
  display: flex;
  justify-content: space-between;
  padding: 6px 0;
}
.bit-porto-goal-number p {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.bit-porto-recalculate {
  align-items: center;
  border: 1px solid #e4e4e4;
  border-radius: 20px;
  color: #00ab6b;
  display: flex;
  padding: 3px 6px;
}
.bit-porto-recalculate p {
  font-size: 13px;
  font-weight: normal;
  margin-left: 2px;
}
.bit-porto-goal-indicator .bit-add-rec-btn {
  font-size: 13px;
  border: 1px solid #00ab6b;
  margin: 16px 0 0;
}

.bit-porto-bar-wrap {
  background: var(--highlight);
  padding: 2px 3px;
  border-radius: 18px;
  height: 18px;
  margin: 10px 0 26px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.bit-porto-bar-wrap.--dark {
  background: #292929;
}

.bit-porto-bar-indicator-back {
  align-items: center;
  background: var(--green-high);
  border-radius: 8px;
  color: #fff;
  display: flex;
  font-weight: bold;
  height: 14px;
  max-width: calc(100% - 6px);
  min-width: 23px;
  width: 20%;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.bit-porto-bar-wrap.--dark .bit-porto-bar-indicator-back {
  background: #103d2d;
}
.bit-porto-bar-indicator {
  position: absolute;
  top: 2px;
  z-index: 2;
  align-items: center;
  background: #9343c8;
  border-radius: 8px;
  color: #fff;
  display: flex;
  font-weight: bold;
  height: 14px;
  max-width: calc(100% - 6px);
  min-width: 23px;
  width: 20%;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.bit-porto-bar-wrap.--dark .bit-porto-bar-indicator {
  background: #8228bd;
}
.bit-porto-bar-indicator p {
  width: 100%;
  text-align: center;
  font-size: 11px;
  transform: translateX(4px);
}
.bit-porto-indicator-line {
  align-items: center;
  display: flex;
  justify-content: space-around;
}
.bit-porto-indicator-line span {
  background: var(--border);
  width: 2px;
  height: 5px;
}
.bit-porto-goal .recurring-payment-method label {
  min-height: 40px;
}
.bit-porto-goal .recurring-payment-method {
  margin-top: 14px;
}
.bit-porto-goal .bit-recurring-slide {
  margin-top: 14px;
}
.bit-chart-space {
  margin-bottom: 20px;
  width: 100%;
  margin-top: -100px;
  height: 200px;
}
.bit-chart-fill {
  width: calc(100% + 40px);
  margin-left: -20px;
  height: 165px;
}
.bit-nomargin-top {
  margin-top: 0px;
}
.bit-porto-recurring-slide {
  border-radius: 5px;
  border: solid 1px #e4e4e4;
  height: 44px;
  margin: 20px 0;
  padding: 7px 20px;
}
.bit-porto-recurring-slide .am-slider {
  margin: 0 20px !important;
}
.bit-porto-recurring-slide .am-slider-track {
  background: transparent;
}
.bit-porto-recurring-slide .am-slider-rail {
  background-color: transparent;
  background-image: linear-gradient(
    to right,
    rgb(0, 171, 107) 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-repeat: repeat-x;
  background-size: 12px 10px;
  background-position: center center;
  height: 1px;
  width: 100%;
}
.bit-porto-recurring-slide .am-slider-handle {
  background-color: #00ab6b;
  padding: 13px;
  transform: translateY(-4px) translateX(-5px);
}
.bit-porto-recurring-slide .am-slider-handle:after {
  align-items: center;
  content: '24';
  color: #fff;
  display: flex;
  font-size: 10px;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  position: relative;
}
.bit-porto-right {
  padding-left: 50px;
}
.bit-porto-form .am-list-item .am-list-line .am-list-content {
  font-size: 16px;
}
.bit-porto-goal.shimmer .bit-porto-goal-circle {
  background-image: linear-gradient(
    to right,
    #eaeaea 0%,
    #f5f5f5 20%,
    #f5f5f5 40%,
    #eaeaea 100%
  );
  -webkit-animation: shimmer 6s forwards linear infinite;
  animation: shimmer 6s forwards linear infinite;
}
.bit-porto-goal-empty {
  background: var(--surface);
  height: 100%;
  padding: 22px 20px 18px;
  text-align: center;
  min-height: 450px;
}
.goal-empty-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% + 3px);
}
.bit-porto-goal-empty .with-goal-target ~ .goal-empty-content {
  height: calc(100% - 45px);
}
.goalEmptyImage {
  margin-bottom: 16px;
}
.moreBulletTheme {
  display: flex;
  padding: 14px;
  position: absolute;
  top: 7px;
  right: 6px;
}
.moreBulletTheme b {
  border: solid 1px #ccc;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  display: block;
  margin: 0 1px;
}
.moreBulletThemeGreen {
  display: flex;
  padding: 14px;
  position: absolute;
  top: 7px;
  right: 6px;
}
.moreBulletThemeGreen b {
  border: solid 1px #00ab6b;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  display: block;
  margin: 0 1px;
}
.moreBullet {
  display: flex;
  flex-direction: column;
}
.moreBullet b {
  background-color: var(--icon-default);
  border-radius: 50%;
  width: 4px;
  height: 4px;
  display: block;
  margin: 1px 0;
}
.bit-new-style-btn {
  transform: translateY(-10px);
}
.bit-porto-style {
  padding: 0 20px 20px 20px;
}
.bit-porto-style .bit-modal-title {
  margin: 0;
}
.bit-porto-style .bit-opt-right-2 {
  margin-left: 10px;
  margin-top: 2px;
}
.bit-porto-style .bit-payment-opt {
  display: flex;
}
.bit-porto-style .bit-payment-opt label {
  width: calc(100% / 2);
}
.bit-porto-style .bit-title-themes {
  color: #b5b5b5;
  font-size: 13px;
  margin: 0 0 5px;
  text-align: left;
}
.bit-porto-style .bit-payment-opt + .bit-title-themes {
  margin-top: 20px;
}
.bit-porto-style .bit-payment-opt {
  border: solid 1px #f0f0f0;
  border-radius: 4px;
}
.bit-porto-style .bit-opt-row {
  margin-bottom: 0;
}
.bit-porto-style .bit-opt-row {
  padding: 8px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bit-porto-style .bit-opt-row + .bit-opt-row {
  border-left: solid 1px #f0f0f0;
}
.bit-porto-style .am-checkbox-inner {
  top: 0;
  left: 0;
}
.manual-payment .bit-opt-inline img {
  margin-right: 10px;
  width: 60px;
}

/* ----- Style for card to list ----- */
.styleList .porto-card-container.port-categories {
  padding: 0;
}
.styleList .port-categories .am-card {
  border: 0 !important;
  margin-bottom: 0;
}
.styleList .port-categories .am-card + .am-card {
  border-top: 1px solid #ededed !important;
}

.styleList .bit-add-porto-btn {
  width: calc(100% - 40px);
  margin: 10px 20px;
}

/* ----- change product css ----- */
.bit-more-product-modal {
  text-align: left;
}
.bit-more-product-content {
  display: flex;
  justify-content: center;
}
.bit-more-product-content .bit-product-content-menu {
  border: 1px solid #ededed;
  display: flex;
  height: 110px;
  justify-content: center;
  width: calc((100% - 30px) / 3);
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.025);
}
.bit-more-product-content .bit-product-content-menu:first-child {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.bit-more-product-content .bit-product-content-menu:nth-child(2) {
  margin: 0 15px;
}
.bit-more-product-content .bit-product-content-menu:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.bit-product-content-menu {
  align-items: center;
  text-align: center;
}
.bit-product-content-menu:hover {
  background: rgba(0, 171, 107, 0.05);
}
.bit-product-content-menu img {
  height: 45px;
  margin-bottom: 8px;
}
.bit-product-content-menu p {
  color: var(--text-primary);
  font-size: 12px;
  text-align: center;
  padding-top: 10px;
}
.bit-product-change-menu {
  height: 48px;
  margin-bottom: 8px;
}
.bit-product-change-menu div {
  overflow: hidden;
  position: relative;
}
.bit-product-change-menu div img {
  height: 100%;
  width: 100%;
}
.bit-product-change-menu div:first-child {
  background: #ededed;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  transform: translate(11px, -1px);
}
.bit-product-change-menu div:nth-child(2) {
  background: #00ab6b;
  height: 1px;
  transform: translate(21px, 5px) rotate(57deg);
  width: 7px;
}
.bit-product-change-menu div:nth-child(3) {
  background: #ededed;
  border-radius: 50%;
  height: 23px;
  transform: translate(24px, 6px);
  width: 23px;
}
.bit-product-change-menu div:nth-child(4) {
  background: #00ab6b;
  height: 1px;
  transform: translate(32px, -31px) rotate(11deg);
  width: 18px;
}
.bit-product-change-menu div:nth-child(5) {
  background: #00ab6b;
  height: 1px;
  transform: translate(46px, -19px) rotate(-45deg);
  width: 8px;
}
.bit-product-change-menu div:last-child {
  background: #ededed;
  border-radius: 50%;
  height: 10px;
  transform: translate(54px, -34px);
  width: 10px;
}
.bit-product-content-menu > div:before {
  content: '';
  display: block;
  width: 70px;
  height: 70px;
  background: var(--modal-green-low);
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  margin: auto;
}
.bit-product-content-menu > div * {
  position: relative;
}
.bit-more-product-content .bit-product-content-menu {
  box-shadow: none;
  border: none;
  position: relative;
}

/* profiling goal */
.profilRisikoTop {
  display: flex;
  flex-direction: column;
  padding: 15px 20px 20px 25px;
  position: relative;
  border: solid #f9f9f9;
  border-width: 10px 0 10px 0;
  background: #fff;
  text-align: left;
  font-size: 13px;
}

.profilRisikoTop img {
  padding: 10px;
}

.profilRisikoTop > div:nth-child(1) {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profilRisikoTop .lineborder {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 5px;
  background: #ccc;
}

.profilRisikoTop .agresif.lineborder {
  background: #9343c8;
}

.profilRisikoTop .moderat.lineborder {
  background: #2478b4;
}

.profilRisikoTop .konservatif.lineborder {
  background: #00ab6b;
}

.profilRisikoTop > div:nth-child(1) > div:nth-child(2) {
  font-size: 18px;
  font-weight: bold;
  padding-left: 20px;
}

.profilRisikoTop > div:nth-child(1) > div:nth-child(1) {
  width: 55px;
  height: 55px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  box-shadow: 1px 2px 4px 0 #efebf2;
  border: solid 1px #ededed;
}
.goal .am-navbar-light {
  background: transparent;
}
.goal .am-navbar-light * {
  display: none;
}
.goal .investor-type {
  display: none;
}
.goal .modalfake {
  border-radius: 6px 6px 0 0;
  background: #fff;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.07);
}
.goal .profilRisikoTop {
  background: transparent;
  border: 0;
  padding: 20px 20px 0 20px;
}
.goal .lineborder {
  display: none;
}
.goal .bit-simul-wrap {
  display: none;
}

.logged-in .content-wrapper.goal {
  background: #717171;
}
.circle-menu {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 30px;
  margin-bottom: 15px;
}
.circle-menu-child > div {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: #ccc;
  margin-bottom: 10px;
}
.circle-menu-child {
  text-align: center;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.circle-menu-child.disabledIcon {
  opacity: 0.5;
}
.GoalportoName {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
}
.circle-menu-child p {
  font-size: 13px;
  margin-top: 5px;
}
.bit-cust-delete-text {
  font-size: 14px !important;
  font-weight: bold;
  margin-top: 24px;
}
.bit-porto-goal-bottom .am-button-loading:after {
  content: '';
  background: #99ddc4;
  border: 1px solid #ccc;
  border-right: 1px solid #fff;
  border-radius: 50%;
  height: 16px;
  margin-left: -61px;
  opacity: 0.6;
  position: absolute;
  width: 16px;
  animation: rotate 1s infinite linear;
  display: none;
}

/* --------- Robo Detail --------- */
.bit-modal-porto-class {
  display: flex;
  align-items: center;
}
.bit-modal-porto-class h4 {
  margin-top: 20px !important;
}
.bit-modal-porto-class > div {
  height: 40px;
  background-color: var(--modal-surface);
  width: 40px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.bit-modal-porto-class > div img {
  margin-right: 0;
  width: 100%;
}
.bit-modal-porto-class span {
  font-weight: bold;
}
@media (max-width: 350px) {
  .bit-modal-porto-class span b {
    display: none;
  }
}
.bit-profile-risk-porto-col {
  align-items: center;
  display: flex;
  /* margin-top: 40px; */
  justify-content: space-between;
  width: 100%;
}
.bit-profile-risk-porto-col .value-stepper-val {
  font-weight: bold;
}
.bit-modal-risk-page .porto-goal-top-1 {
  display: none !important;
}
.bit-modal-risk-page .bit-profile-risk-porto-col {
  margin-top: 0;
}
.bit-modal-risk-page .porto-goal-top {
  border-bottom: solid 0 #f5f5f5;
}
.bit-modal-risk-page .porto-goal-top-desc > div:nth-child(1) {
  font-weight: 200 !important;
}

/* --------- Home Portfolio --------- */
.bit-new-portocard-row .am-card {
  margin-right: 20px;
}
.bit-new-portocard-row .am-card:last-child {
  margin-right: 0px;
}
.bit-new-portocard-row .bit-circle-add-wrap {
  margin-top: 15px;
  padding-right: 20px;
  transform: translateX(-16px);
}

/* --------- Profile Risiko --------- */
.profile-risiko-modal {
  padding-top: 20px;
}
.profile-risiko-modal b {
  padding-bottom: 10px;
  font-size: 16px;
  display: block;
  text-align: left;
}

/* -------- Reksa Chart -------- */
.navValueBox {
  display: flex;
  align-items: flex-end !important;
  justify-content: center;
}
.navDate {
  display: inline-block;
  padding-left: 3px;
  font-size: 12px;
  /* margin-right: -20px; */
  position: relative;
  top: -1px;
}

.realizedprofit {
  flex-direction: row !important;
}

.realizedprofit button {
  background-color: unset;
  border: unset;
  color: var(--green-default);
}

/* --------Order List -------- */
.OrderTopList {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px 0;
  border-top: solid 1px var(--divider-thin);
  color: var(--text-primary);
}

.OrderTopList.OrderTopList-hide-border-top {
  border-top: unset !important;
}

.OrderTopList span:nth-child(1) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.OrderTopList span:nth-child(2) {
  font-weight: bold;
  white-space: nowrap;
  padding-top: 3px;
  font-size: 15px;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

/* -------- Robo Profiling -------- */
.profiling-header-item {
  display: flex;
  justify-content: space-between !important;
}
.profiling-rec-item {
  padding: 0 0 0 20px;
  justify-content: space-between !important;
}
.am-modal-body .profiling-rec-item {
  padding: 0 20px 0 20px;
}
.infoIcon {
  position: relative;
  top: 1px;
  margin-left: 5px;
  z-index: 99;
}

.profiling .infoIcon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.profiling .infoIcon svg {
  position: relative;
  top: -1px;
}

/* -------- Empty State -------- */
.empty-state-box {
  text-align: center;
  margin: auto;
  padding-top: 50px;
}

.empty-state-box img {
  display: flex;
  margin: auto;
  height: 150px;
}

.empty-state-box p:nth-child(2) {
  padding: 24px 0px 8px;
  font-size: 16px;
  font-weight: bold;
  color: var(--text-primary);
}

.empty-state-box p:nth-child(3) {
  margin: 0px 30px;
  font-size: 14px;
  line-height: 1.5rem;
  color: var(--text-primary);
}
.empty-state-box div:nth-child(2) {
  padding-top: 20px;
  margin: 10px 0px;
  font-size: 16px;
  font-weight: bold;
  color: var(--text-primary);
}

.empty-state-box div:nth-child(3) {
  margin: 10px 30px;
  font-size: 14px;
  line-height: 1.5rem;
  color: var(--text-secondary);
}

.empty-state-box button {
  margin: 10px auto;
  width: 60%;
  text-align: center;
  color: #fff;
  background: #00ab6b;
  border-radius: 20px;
  border: 0;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

@media screen and (max-width: 1024px) {
  .empty-state-box button {
    margin: 15px auto;
  }
}

/* ----- Sell All on Robo Sell Modal ----- */

.confirmRadio {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding-left: 15px;
  padding-bottom: 10px;
  margin-top: 10px;
  text-align: justify;
  font-size: 13px;
}
.sell-modal-confirmation-radio {
  padding-left: 0px;
}
.sell-modal-confirmation-radio p {
  padding-left: 7px !important;
  color: var(--text-primary);
}
.sell-modal-confirmation-radio > div {
  border-color: var(--inactive);
}
.sell-modal-confirmation-radio--uncheck > div > img {
  display: none;
}
.bit-bank-arrow {
  margin: 3px 0 0 0;
  position: absolute;
  left: 50%;
  margin-left: -16px;
}
.bit-bank-arrow.permata-arrow {
  margin-left: 6px;
}
.bit-bank-arrow.instruction-arrow {
  position: unset;
  margin-left: auto;
  margin-right: 15px;
  margin-top: unset !important;
}
.bit-bank-arrow-top {
  margin: -42px 0 0 0;
  position: absolute;
  right: 36px;
}

.confirmRadio p {
  text-align: justify;
  padding-left: 15px;
  font-size: 13px;
}

.bit-sell-confirm-checkbox {
  padding: 10px 0;
  align-items: flex-start;
}

.bit-sell-confirm-checkbox > input + div {
  width: 17px;
  height: 17px;
}

.bit-sell-confirm-checkbox p {
  text-align: justify;
  padding-left: 7px;
  font-size: 13px;
}

/* ----- Simulation ----- */

.bit-invest-slide-wrap .am-slider-handle {
  transform: translate(-30px, -34px);
  padding: 44px;
  background: transparent;
  border: none;
  width: 22px;
  height: 22px;
}
.bit-invest-slide-wrap .am-slider-handle:after {
  content: '';
  position: absolute;
  margin-left: -12px;
  margin-top: -10px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #00ab6b;
  background-color: var(--modal-surface);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.bit-simulation-cont {
  padding-right: 14px;
  position: relative;
  width: 100%;
  margin-right: 0;
}
.bit-simulation-cont h4 {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  color: #333;
  margin: 0 0 4px;
}
.bit-simulation-cont p {
  color: #7b7b7b;
  margin-bottom: 4px;
}
.bit-simulation-cont .right-arr {
  position: absolute;
  right: -10px;
  top: 50%;
  margin-top: -10px;
  color: var(--icon-default);
}

/* --- New Filter --- */
.bit-new-filter {
  border-top: 1px solid #f5f5f5;
  margin: 8px 0px;
  text-align: left;
  padding-top: 8px;
}
.bit-filter-items {
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  margin: 12px 0;
  justify-content: space-between;
  padding-bottom: 12px;
}
.bit-filter-items div {
  color: var(--text-primary);
  border: 1px solid var(--modal-border);
  border-radius: 20px;
  flex: 1;
  margin: 0 4px;
  padding: 3px 6px;
  text-align: center;
}
.bit-filter-selected {
  border: solid 1px #00ab6b !important;
  background-color: rgba(0, 171, 107, 0.1);
}
.bit-new-sorting-title {
  font-weight: 700;
  font-size: 13px;
}
.bit-new-sorting {
  margin: 0 20px;
  padding: 10px 0 14px;
  text-align: left;
}
.bit-new-sorting .bit-filter-selected {
  background-color: transparent;
  color: #00ab6b;
  font-weight: 700;
  border: solid 1px #00ab6b;
}
.bit-sorting-items {
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0 0;
  justify-content: flex-start;
}
.bit-sorting-items.bit-order {
  margin: 0;
  flex-direction: column;
}
.bit-sorting-items.bit-sorting div {
  border: 1px solid #f0f0f0;
  border-radius: 15px;
  flex: 0 1;
  flex-basis: auto;
  margin: 0 8px 14px;
  padding: 3px 6px;
  text-align: center;
  align-self: center;
  font-size: 12px;
}
.bit-sorting-items.bit-order div input[type='radio'] {
  display: none;
}
.bit-sorting-items.bit-order div input[type='radio'] + img {
  width: 18px;
  height: 18px;
  border: 1.5px solid #cbcbcb;
  border-radius: 100%;
  margin-right: 5px;
}
.bit-sorting-items.bit-order div input[type='radio'] + img.bit-filter-checked {
  border: none;
}
.bit-sorting-items.bit-periode div {
  border: 1px solid var(--border);
  border-radius: 15px;
  color: var(--text-primary);
  flex: 0 1;
  flex-basis: auto;
  margin: 0 4px 12px;
  padding: 6px 16px;
  text-align: center;
  align-self: center;
  font-size: 12px;
}
.bit-modal .bit-sorting-items.bit-periode div {
  border: 1px solid var(--modal-border);
}
.bit-sorting-items.bit-order div {
  margin: 5px 8px;
  display: flex;
  align-items: center;
}

.bit-filter-button-wrapper {
  display: flex;
  text-align: center;
}

.bit-filter-button-wrapper span {
  font-weight: 700;
  font-size: 14px;
}

.bit-filter-button-wrapper .am-button-ghost {
  color: #00ab6b;
}
.bit-filter-button-wrapper:nth-last-child() {
  margin-right: 0px;
}
.bit-filter-button-wrapper div {
  flex-grow: 1;
}
.bit-filter-button-reset {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #00ab6b;
  color: #00ab6b;
  border-radius: 4px;
  font-size: 16px;
}
.bit-filter-button-reset.disabled {
  opacity: 0.5;
  cursor: default;
}

.bit-sort-modal .bit-modal-body {
  padding: 20px 20px 32px 20px;
}

/* --- Order Detail --- */
.payment-row {
  background-color: var(--surface);
  bottom: 0;
  left: 0;
  height: 128px;
  padding: 24px;
  position: fixed;
  width: 100%;
  z-index: 1;
  border-top: 1px solid var(--border);
}

/* --- Get started banner --- */
.get-started-banner {
  border: 0px;
  background: var(--surface);
  border-radius: 4px;
  color: var(--text-primary);
  display: flex;
  margin-bottom: 20px;
  margin-top: 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 6px 0;
}
.get-started-banner h4 {
  margin: 0;
  margin-bottom: 5px;
  font-size: 15px;
}
.get-started-banner img {
  width: 100%;
}
.get-started-banner .icon > picture {
  display: flex;
}
.get-started-banner > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.get-started-banner > div:nth-child(1) {
  width: 72px;
  height: 82px;
  align-items: center;
  display: flex;
  margin-left: 6px;
}
.get-started-banner > div:nth-child(2) {
  width: calc(100% - 78px);
  text-align: left;
  padding: 0 15px;
  align-self: center;
}

.get-started-banner .icon-box {
  width: 72px;
  height: 72px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.get-started-banner .icon-box > img {
  width: 27px;
}

.bibit-plus-banner {
  padding: 6px 12px 6px 6px;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: flex-start;
}

.bibit-plus-banner img {
  height: 72px;
}

.bibit-plus-banner > div:nth-child(1) {
  min-width: 72px;
  margin-right: 12px;
  width: unset;
}
.bibit-plus-banner > div:nth-child(2) {
  padding: 0;
  align-self: unset;
  width: unset;
}

.bibit-plus-banner h4 {
  font-size: 14px;
}

.gopay-row {
  background: var(--cobalt-low);
  color: var(--cobalt-default);
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  padding: 4px 18px 4px 21px;
}

.gopay-row div > svg {
  width: 16px;
}

.gopay-row div {
  display: flex;
}

.failed-token-verify {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.failed-token-verify-component {
  border-radius: 4px;
  background: #fff;
  border: solid 1px #ededed;
  text-align: center;
  padding: 100px 50px;
}

.failed-token-verify-component > div:nth-child(1) img {
  max-width: 280px;
  width: 100%;
  margin-bottom: 50px;
}
.failed-token-verify-component > div:nth-child(2) {
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 20px;
}

/* --- Random --- */
.ma4mccd {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ma4mccdwr {
  border-radius: 4px;
  background: #fff;
  border: solid 1px #ededed;
  text-align: center;
  padding: 100px 50px;
}

.ma4mccdwr > div:nth-child(1) img {
  max-width: 280px;
  width: 100%;
  margin-bottom: 50px;
}
.ma4mccdwr > div:nth-child(2) {
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 20px;
}

.iconRecurring {
  margin-left: 20px;
  margin-right: 10px;
}
.profiling-rec-body {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  background: #fff;
}
.profiling-rec-body .am-flexbox-item {
  text-align: left;
  width: 100%;
}
.profiling .porto-goal-top {
  border-top: solid 6px #f5f5f5;
  border-bottom: solid 5px #f5f5f5;
  padding: 20px 20px 10px 20px;
}
.profiling .porto-goal-top:after {
  display: none;
}
.va-wrap {
  background: #f9f9f9;
  height: 100%;
  overflow: unset;
  padding: 20px;
  position: relative;
  width: 100%;
}
.bit-bank-arrow {
  margin: 3px 0 0 0;
  position: absolute;
  left: 50%;
  margin-left: -16px;
}
.bit-bank-arrow.permata-arrow {
  margin-left: 6px;
}

.bit-bank-arrow-top {
  margin: -42px 0 0 0;
  position: absolute;
  right: 36px;
}
.bit-prof-separator {
  background: var(--divider-thick);
  height: 10px;
  width: 100%;
}
.profile .bit-prof-separator {
  height: 8px;
}
.bit-custom-input-code {
  display: flex;
}
.bit-custom-input-code span {
  align-items: center;
  color: var(--text-primary);
  border-bottom: solid 1px var(--border);
  font-size: 16px;
  display: flex;
  height: 42px;
  justify-content: center;
  margin-right: 20px;
  width: 24px;
  text-align: center;
}
.bit-custom-input-code input {
  align-items: center;
  color: var(--text-primary);
  border: none;
  border-bottom: solid 1px var(--border);
  border-radius: 0px;
  font-size: 16px;
  display: flex;
  height: 42px;
  justify-content: center;
  margin-right: 20px;
  width: 28px;
  text-align: center;
  background: none;
}
.bit-custom-input-code input:disabled {
  background: none;
  color: var(--text-primary);
  -webkit-text-fill-color: var(--text-primary);
  opacity: 1;
}
.wrapper-toast-top {
  position: fixed;
  transform: translateY(-100%);
  width: 100%;
  z-index: 9999;
  top: 0;
  transition: 0.2s ease-out;
  opacity: 0;
}
.wrapper-toast-top.open {
  transform: translateY(0);
  transition: 0.2s ease-out;
  opacity: 1;
}
.wrapper-toast-top > div {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  padding: 20px;
}
.wrapper-toast-top > div span {
  display: inline-block;
  font-weight: bold;
  margin-left: 6px;
}
.wrapper-toast-top.green {
  background: var(--green-high);
}
.wrapper-toast-top.green > div span {
  color: var(--green-default);
}
.wrapper-toast-top.red {
  background: var(--red-high) !important;
}
.wrapper-toast-top.red > div span {
  color: var(--red-default);
}

.bit-currency-opt .am-list-body {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.bit-currency-opt .am-radio-wrapper {
  align-items: center;
  border: 1px solid #ededed;
  border-radius: 5px;
  display: flex;
  height: 42px;
  justify-content: space-between;
  padding: 0 15px;
  width: 48%;
}
.bit-currency-opt .am-radio-wrapper.checked {
  border: 1px solid #00ab6b;
}
.bit-currency-opt .am-radio {
  order: 2;
}
.bit-currency-opt p {
  order: 1;
}
.bit-currency-opt .am-radio-inner {
  border: 1px solid #b5b5b5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bit-currency-opt .am-radio-inner:after {
  border: 2px solid #ededed;
  border-radius: 50%;
  height: 13px;
  right: -1px;
  top: -1px;
  width: 13px;
}
.bit-currency-opt .am-radio.am-radio-checked .am-radio-inner:before {
  background: #00ab6b;
  border-radius: 50%;
  display: block;
  content: '';
  height: 9px;
  width: 9px;
}
.bit-currency-opt .am-radio.am-radio-checked .am-radio-inner:after {
  border-color: #00ab6b;
}
.bit-sim-flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.bit-sim-flex div {
  flex: 1;
}

.bit-sim-flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.profiling-rec-flex {
  background: #00ab6b;
  padding: 10px 20px 20px 20px;
  color: #fff;
  text-align: center !important;
}
.profiling-rec-fragment span {
  white-space: nowrap;
}
.profiling-rec-fragment {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}

.profiling-rec-button-second .am-button {
  height: 34px;
  line-height: 34px;
  font-size: 14px;
  color: #b5b5b5;
}

.profiling-rec-button-second .am-button::before,
.profiling-rec-button-second .am-button::after {
  border: 0 !important;
}

.profiling-rec-button a {
  background: #00ab6b;
  color: #fff;
  border-radius: 4px;
  text-align: center;
}

.profiling-left {
  display: flex;
}

.profiling-left p {
  display: contents;
  margin-right: 15px;
  font-weight: 600;
}

.profiling-right {
  text-align: right !important;
  margin-right: 15px;
  font-weight: 600;
  font-size: 16px;
}

.profiling-list-left {
  font-size: 14px;
  color: #b5b5b5;
  margin: 5px 0px;
  text-align: left;
  display: flex;
  align-items: center;
}

.profiling-list-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  color: #b5b5b5;
  margin: 5px 0px;
  text-align: right !important;
}

.recomend-logo-wrapper span {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50px;
}

.profiling-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
}

.circle-tag {
  width: 20px;
  height: 20px;
  background: #00ab6b;
  border-radius: 50px;
  margin: 10px 10px 10px 0px;
}
.profiling-list > div:nth-child(1) {
  justify-content: flex-start;
  width: 18px;
}
.profiling-list > div:nth-child(3) {
  width: 80px;
  justify-content: flex-end;
}
.profiling-list > div:nth-child(3) {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.profiling-list > div:nth-child(3) span {
  width: 8px;
  height: 8px;
  background: #bbb;
  border-radius: 10px;
  display: inline-block;
  margin-right: 5px;
}

.profiling-list > div:nth-child(2) {
  width: calc(100% - 100px);
}

.profiling-rec-data span {
  display: block;
  white-space: nowrap;
}

.profiling-rec-data span:nth-child(1) {
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: center;
}
.profiling-rec-data span:nth-child(2) {
  font-size: 12px;
  color: #b5b5b5;
  margin-top: 2px;
}

/* .profiling-list-right {
  display: contents;
} */

.profiling-list-right p {
  margin: 15px 0px;
  width: 50%;
  float: right;
  color: #333;
  font-weight: 600;
}

.profiling-list .am-list-body::after,
.profiling-list .am-list-body::before {
  background-color: transparent !important;
}

.recomend-logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recomend-logo-wrapper img {
  width: 36px !important;
  height: 36px !important;
}

.profile-risiko-span {
  font-weight: 600;
  color: #00ab6b;
  font-size: 16px;
  text-align: left;
  width: 100%;
  margin: 15px 15px 25px 15px;
  display: block;
}

.profile-risiko-p {
  font-weight: 500;
  color: #00ab6b;
  text-align: left;
  color: #b5b5b5;
  margin: 25px 15px 15px 15px;
  display: block;
}

/* Profiling Card */

.profiling-card-modal .am-navbar-left {
  padding-left: 0;
}

.profiling-card-modal .am-modal-body {
  padding-bottom: 70px;
}

.profiling-card-info {
  font-size: 15px;
  text-align: center;
  color: #b5b5b5;
  text-transform: capitalize;
}

.profiling-card {
  background: var(--surface);
  padding-bottom: 0px !important;
}

.profiling-card-header {
  display: grid;
  flex: 1;
}

.profiling-card-header img {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  margin: 0px 5px 0px 0px;
}

.profiling-card-header p {
  color: var(--text-primary);
  font-size: 15px;
  font-weight: bold;
  margin: 0px 0px 0px 10px;
}

.profiling-card-header a {
  font-size: 13px;
  color: #00ab6b;
  float: left;
  margin-left: 10px;
  padding-top: 4px;
}

.am-card-body.profiling-card-body {
  padding: 10px 15px;
}

.profiling-card-body span {
  font-size: 12px;
  color: var(--text-secondary);
  font-weight: 500;
}

.profiling-card-body p {
  font-weight: 500;
  font-size: 13px;
  margin: 0px;
}
.bottom-content >div:not(:first-child) p{
  color: var(--text-primary);
}

.profiling-card-footer {
  background: #ededed;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.profiling-card-footer .am-card-footer-content {
  display: none;
}

.profiling-card-footer .am-card-footer-extra a {
  font-size: 12px;
  font-weight: 600;
}

.profiling-card-footer .am-button::before {
  border: 0px !important;
}

.p-left {
  text-align: left;
}

.p-center {
  text-align: center;
}

.p-right {
  text-align: right;
}

.instant-redemption-footer-card {
  background: var(--green-low);
  padding: 8px 12px;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.content-box-inmodal .instant-redemption-footer-card,
.profiling-card-fragment-inside-modal .instant-redemption-footer-card {
  background: var(--modal-green-low);
}

.instant-redemption-footer-card img {
  display: flex;
}

.roboSelected {
  background: #00ab6b;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  transform: rotate(45deg);
  content: '';
  display: block;
  position: absolute;
  text-align: center;
  top: 18px;
  right: 13px;
  z-index: 50;
}

.roboSelected svg {
  transform: rotate(-45deg);
  position: relative;
  top: 1px;
  width: 12px;
}

.card-selected {
  overflow: hidden;
}

.roboSelected-btn {
  width: 100%;
  text-align: center;
  color: #fff;
  background: #00ab6b;
  border-radius: 4px;
  border: 0;
  height: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 15px;
  margin-bottom: 20px;
}

/* JSP */

.investor-type {
  text-align: justify;
}
.profiling-card-modal .discoverMoreBtn {
  color: #00ab6b !important;
  border-color: #00ab6b !important;
  margin: 0;
  width: 100%;
}
.whitestar {
  position: absolute;
  top: 18px;
  right: 13px;
  display: none;
}
.whitestar-badge .whitestar {
  display: flex;
}
picture.whitestar > img {
  width: 18px;
  height: 18px;
}
.am-card-body.profiling-card-body {
  position: static;
  border-top: solid 1px var(--border) !important;
}
html:not([data-scale]) .am-card-body::before {
  background-color: transparent !important ;
}
.profiling-result-chart {
  margin: 0 auto;
  position: relative;
  width: 320px;
}

.product-list-category-modal
  .get-started-page
  .bit-reksa-cards-wrap
  .am-card.card-selected {
  border: 1px solid var(--green-default) !important;
  position: relative;
}

.bit-two-line-text-img {
  align-items: center;
  border-radius: 4px;
  display: flex;
  padding: 10px 14px;
  text-align: left;
  width: 100%;
}
.portfolio .am-card {
  background: var(--surface);
  min-height: auto;
  padding-bottom: 0;
}
.index-recomend-card-box {
  background: var(--surface);
  box-shadow: none;
  border-top: solid 1px var(--divider-thin);
  border-bottom: solid 1px var(--divider-thin);
  padding-bottom: 24px;
}
.index-recomend-card-box.bit-robo-slider {
  overflow: hidden;
}
.index-recomend-card-box .w1 {
  font-size: 18px;
  margin-bottom: 10px;
  color: var(--text-primary);
}
.index-recomend-card-box .w3 {
  color: #333;
}

/* Bit track status label */
.bit-track-status-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #e4e4e4;
  border-radius: 20px;
  color: #00ab6b;
  font-weight: bold;
  padding: 2px 6px;
  background: #fff;
}
.bit-track-status-wrapper > div {
  background: #00ab6b;
  border-radius: 50%;
  height: 15px;
  margin-right: 5px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bit-track-status-wrapper.offtrack > div {
  background: #ee4a49;
}
.bit-track-status-wrapper.offtrack > p {
  color: #ee4a49;
}
.bit-track-status-wrapper > div > img {
  transform: rotate(180deg);
  width: 8px;
}
.text-wrap {
  white-space: normal;
  word-break: break-word;
  text-align: left;
}
.text-wrap-center {
  white-space: normal;
  word-break: break-all;
  text-align: center;
}
.text-wordwrap-center {
  white-space: normal;
  word-break: break-word;
  text-align: center;
  color: var(--text-primary);
}
.bit-celebrate-confetti-animation,
.content-wrapper.reksadana .bit-celebrate-confetti-animation,
.content-wrapper.reksadana .bit-celebrate-confetti-animation {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  pointer-events: none;
}

.cek2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cashbackicon {
  position: absolute;
  right: 0;
}

.cashbackicon img {
  margin-right: 0 !important;
}
.cek2 span {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  background: var(--surface);
  width: 30px;
  height: 30px;
  color: var(--text-primary);
}

.cek3 {
  border-radius: 5px;
  border: solid 1px var(--border);
}

.cek2 span:nth-child(1) {
  justify-content: flex-end;
}

.cek2 span:nth-child(3) {
  justify-content: flex-start;
}

.cek2 > div:nth-child(2) {
  width: 50px;
  text-align: right;
}
.cek3 > div:nth-child(2) {
  width: calc(100% - 40px);
}

.bit-dialog.dialog-absolute {
  position: absolute;
}

.bit-dialog .bit-dialog-mask {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1001;
}
.bit-dialog .bit-dialog-wrapper {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 1001;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.robo-content-box-wrap .am-navbar-light {
  background: var(--surface);
}

.porto-card-pad .porto-card-top {
  border-bottom: solid 1px var(--border);
  display: flex;
  align-items: center;
  font-size: 17px;
  padding: 9px 15px;
  margin-bottom: 8px;
}
.porto-card-top {
  padding-right: 0 !important;
}

.institution-register-option-modal .bit-modal-header {
  padding: 20px;
  border-bottom: 1px solid var(--modal-border);
}

@media only screen and (min-width: 1030px) {
  .bit-recurring-slide .cek2 span {
    background: none;
  }
  .porto-card-pad .porto-card-top {
    border: none !important;
    margin-bottom: 0 !important;
  }
  .bit-modal-body .content-header {
    background-color: var(--surface-modal) !important;
  }
  .whitestar {
    margin-top: -10px;
    top: 50%;
    right: 13px;
  }
  .roboSelected {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    right: 13px;
  }
  .robo-content-box-wrap .am-navbar-light {
    background: var(--surface-gray);
  }
  .bit-two-line-text-img {
    background: var(--surface);
  }
  .bit-porto-card-top {
    min-width: 330px;
  }
  .card-selected .bit-porto-card-top {
    min-width: auto;
  }
  .am-card.profiling-card {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 68px;
  }
  .am-card.profiling-card .am-flexbox .am-flexbox-item {
    align-items: center;
    display: flex;
    flex: unset;
    flex-direction: column-reverse;
    margin-left: 20px;
  }
  .am-card.profiling-card .am-flexbox .am-flexbox-item span {
    white-space: nowrap;
  }
  .am-card.profiling-card .am-flexbox.am-flexbox-justify-between {
    justify-content: flex-end;
  }
  .bit-create-modal-goal-wrapper .bit-modal-body {
    max-width: 468px;
  }
  .am-card-body.profiling-card-body {
    border-top: 0 !important;
    padding: 15px 40px 15px 15px;
  }
  .profiling-card-body span {
    font-size: 13px;
    padding-top: 3px;
  }
  .profiling-card-body p {
    font-size: 15px;
  }
  .profiling-card-header a {
    display: inline-block;
    padding-top: 3px;
  }
  .bit-prof-separator {
    background: none;
  }
  .va-wrap {
    padding: 20px 0;
  }
  .profiling .porto-goal-top {
    border: 0 !important;
  }
  .home-portfolio-wrap {
    border: solid 1px #ededed !important;
    border-radius: 5px;
  }
  .robo-content-box-wrap .porto-card {
    display: flex;
    justify-content: space-between;
  }
  .porto-card-pad {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 20px;
  }
  .porto-card-pad .porto-card-top {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .porto-card .porto-card-pad .porto-card-top {
    padding: 0;
  }

  .porto-card-pad .porto-card-btm {
    padding: 0;
  }
  .porto-card-btm-child {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 6px;
    text-align: right !important;
  }
  .porto-card-btm {
    padding: 15px 20px;
  }
  .porto-card-btm > div {
    margin-left: 20px;
    width: auto;
    font-size: 15px;
  }
  .porto-card-btm-child > div:nth-child(1) {
    white-space: nowrap;
    padding-top: 3px;
  }
  .bit-porto-card-top {
    border-bottom: 0 !important;
    padding: 15px 0px 15px 20px !important;
  }
  .bit-discover-product-modal .porto-card {
    display: flex;
    justify-content: space-between;
    min-height: 68px;
  }
  .bit-porto-goal-empty .recurringBoxPorto {
    display: flex;
    justify-content: center;
  }
  .bit-sorting-items.bit-periode div {
    cursor: pointer;
    flex-basis: unset !important;
    width: auto !important;
  }
  .ytd {
    margin-right: unset !important;
  }
  .recurring-portfolio-opt label {
    width: 120px;
  }
  .payment-row {
    left: 50%;
    margin-left: -384px;
    width: 768px;
  }
  .get-started-page .puller-box {
    overflow-x: hidden;
    height: auto;
  }
  .quizSlider,
  .index-recomend-card-box,
  .bit-reksa-wrap {
    border: solid 1px var(--border) !important;
  }
  .quizSlider,
  .bit-reksa-wrap {
    padding: 0;
  }
  .bit-reksa-wrap-summary.four4 {
    background: none;
    border: 0 !important;
    border-top: solid 1px var(--border) !important;
  }
  .get-started-banner {
    margin: 0;
    border: solid 1px var(--border);
    border-radius: 0;
  }
  .bit-porto-goal-empty {
    background: none;
    height: calc(100vh - 280px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 50px;
  }
  .bit-porto-goal-bottom {
    border-radius: 0 0 6px 6px;
  }
  .bit-porto-goal > div {
    cursor: pointer;
    width: calc(100% / 4);
  }
  .get-started-banner > div:nth-child(2) {
    align-self: center;
  }

  .bit-porto-lock-icon,
  .value-stepper-btn,
  .goalClose,
  .infoIcon,
  .bit-sorting-items.bit-sorting div,
  .gopay-row,
  .bit-filter-items div,
  .bit-add-rec-btn,
  .bit-modal-close,
  .porto-card,
  .bit-stay-header-modal-close,
  .select-recurring-portfolio,
  .recurring-product-row,
  .bit-modal-body-close,
  .infoIconAbsolute,
  .confirmRadio,
  .birthday-picker .am-picker-popup-header-left,
  .birthday-picker .am-picker-popup-header-right,
  .modal-back-new {
    cursor: pointer;
  }
  .bit-title-text h3 {
    font-size: 16px;
  }
  .bit-edit-btn {
    background: #eee;
    padding: 0 12px;
    border-radius: 5px;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    cursor: pointer;
  }
  .portfolio--dark .bit-edit-btn {
    background: var(--highlight);
  }

  .portoRibbonWrap {
    display: block;
  }
  .portoRibbonWrap > div {
    cursor: pointer;
  }

  .profiling-rec-body {
    background: none;
  }
  .profilRisikoTop + .profiling-rec-body {
    background: #fff;
    border-radius: 5px;
    padding: 20px;
  }
  .profilRisikoTop {
    border-radius: 5px;
    overflow: hidden;
  }

  .porto-card-btm,
  .porto-card-top {
    align-items: center;
  }
  .porto-card-logo {
    margin-right: 20px;
  }
  .bit-sorting-items {
    display: grid;
  }
  .bit-sorting-items.bit-sorting {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .bit-sorting-items.bit-sorting div {
    white-space: nowrap;
  }
  .bit-sorting-items.bit-periode {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .bit-recurring-product-box {
    background: #fff;
  }
  .bit-modal-risk-page {
    border-radius: 5px;
    background: #fff;
    border: solid 1px #ededed;
  }
  .blue-top-bar {
    border-radius: 2px;
  }
  .gopay-row {
    margin-bottom: 0;
  }
  .portfolio-wrapper .bit-track-porto {
    /* background: #fff;
        border-left: solid 1px #ededed;
        border-right: solid 1px #ededed; */
    margin: 0 0 20px;
    /* padding: 15px 0 15px 15px; */
  }
  .get-started-banner > div:nth-child(1) {
    width: 80px;
  }
  .get-started-banner > div:nth-child(2) {
    width: calc(100% - 80px);
  }
  .bit-chart-space {
    height: 280px;
  }

  .bit-porto-bar-wrap {
    background: #f1f1f1;
  }
}

@media (min-width: 425px) {
  .bit-sorting-items.bit-sorting div {
    padding: 3px 22px;
  }

  .bit-porto-form-counter .am-list-line {
    padding-right: 24px;
  }
}

@media (max-width: 324px) {
  .bit-sorting-items.bit-sorting div {
    margin: 0 6px 14px;
  }
}

@media (min-width: 425px) {
  .bit-sorting-items.bit-sorting div {
    padding: 3px 22px;
  }
}

@media (max-width: 324px) {
  .bit-sorting-items.bit-sorting div {
    margin: 0 6px 14px;
  }
}

@media screen and (min-width: 1080px) {
  .wrapper-toast-top {
    max-width: 768px;
  }
}

.new-label-rd {
  display: flex;
  align-items: center;
  font-size: 11px;
  color: var(--cobalt-default);
  background: var(--cobalt-low);
  border-radius: 50px;
  padding: 2px 6px;
  font-weight: 700;
  margin-left: 6px;
  height: 20px;
}
.instant-label-rd {
  height: 20px;
  margin-left: 6px;
}
.top-label-rd {
  font-size: 11px;
  color: var(--green-default);
  background: var(--green-low);
  border-radius: 50px 0 0 50px;
  padding: 3px 6px;
  font-weight: 400;
  margin-left: 6px;
  height: 20px;
  display: flex;
  align-items: center;
}

.top-label-rd img {
  margin-right: 3px;
  width: 12px;
  height: 12px;
}
.instant-label-rd {
  height: 20px;
}
.top-label-rd {
  font-size: 11px;
  color: var(--green-default);
  background: var(--green-low);
  border-radius: 50px 0 0 50px;
  padding: 3px 6px;
  font-weight: 400;
  margin-left: 6px;
  height: 20px;
  display: flex;
  align-items: center;
}

.top-label-rd img {
  margin-right: 3px;
  width: 12px;
  height: 12px;
}

.mutualfund-categories--dark .new-label-rd {
  background: #2073ac;
  color: var(--text-white);
}

/* @ PinFormModal.css */

.bit-modal-pin-form .bit-modal-header {
  position: absolute;
  transform: translate(-2px, -4px);
  z-index: 1000;
}
.bit-modal-pin-form .content-header {
  padding: 20px 20px 20px 0px;
}

.bit-modal-pin-form .content-wrapper {
  overflow-y: initial;
}

.bit-modal-pin-form .am-navbar-title {
  margin-left: 20px;
}

.bit-modal-pin-form .am-navbar-right {
  padding-right: 0;
}

.bit-modal-pin-form .bit-modal-body {
  border-radius: 0;
  max-height: 100% !important;
}

@media only screen and (min-width: 1030px) {
  .bit-modal-pin-form .am-navbar-right {
    transform: translateX(0);
  }
  .bit-modal-pin-form .content-header {
    background: var(--surface-modal);
    transform: translate(-2px, 0);
  }
  .bit-modal-pin-form .content-header {
    transform: translateY(-10px);
    padding: 20px;
  }

  .bit-modal-pin-form .bit-link-new-pin {
    margin-top: 50px;
  }

  .bit-link-new-pin .login-register-icon img {
    margin-top: 24px;
  }

  .bit-modal-pin-form .content-wrapper.newPin .content-box {
    border: 0 !important;
  }
  .bit-modal-pin-form .bit-custom-keyboard {
    margin-top: 40px;
  }
}

@media only screen and (max-height: 650px) {
  .login-register-icon img {
    margin-top: 4px;
  }
}

.sso-divider {
  max-width: 500px;
  width: 85%;
  margin: 16px auto;
  display: flex;
  align-items: center;
  color: var(--text-secondary);
}

@media screen and (max-height: 640px) {
  .sso-divider {
    margin: 14px auto;
  }
}

.sso-divider::before {
  content: '';
  height: 1px;
  flex-grow: 1;
  background-color: var(--divider-thin);
  margin-right: 20px;
}

.sso-divider::after {
  content: '';
  height: 1px;
  flex-grow: 1;
  background-color: var(--divider-thin);
  margin-left: 20px;
}

/* Copy from LinkAjaPaymentModel.css due css conflict */

.link-aja-modal {
  text-align: center;
}
.link-aja-modal img {
  width: 125px !important;
}
.link-aja-modal p {
  margin: 10px 0 20px;
}
.link-aja-modal .bit-btn-pay {
  margin: 0;
}
.bit-cancel-btn-link {
  color: #ee4a49;
  margin-bottom: 0 !important;
  text-align: center;
}

.link-aja-modal .linkAja9Logo {
  position: absolute;
  right: 20px;
  width: 35px !important;
}

.linkAjaPayBtn .am-button {
  width: calc(50% - 10px);
}
.linkAjaPayBtn .am-button:nth-child(1) {
  margin-right: 20px;
}
.linkAjaPayBtn {
  display: flex;
  justify-content: space-around;
}

/* COPY FROM ModalUpdateReminder.css due css conflict */
.bit-update-version {
  text-align: center;
}
.bit-update-version img {
  margin: 14px 0;
}
.bit-update-version h3 {
  margin: 14px 0;
  color: var(--text-primary);
}
.bit-update-version p {
  margin: 0 14px;
  color: var(--text-primary);
}
.bit-update-version-btn-wrap {
  padding: 20px 15px 0;
}
.bit-update-version-btn-wrap a:not(:first-child) {
  background: none !important;
  color: #00ab6b;
  margin-top: 10px;
}
.link-aja-update-modal img {
  margin-top: 0;
  margin-bottom: 24px;
}

.link-aja-update-modal svg {
  stroke: #b5b5b5;
}

.sbn-live-offering-carousel {
  width: 102% !important;
}

@media only screen and (max-width: 767px) {
  .sbn-live-offering-carousel {
    width: 105% !important;
  }
}

.best-explore-box {
  background: var(--surface) !important;
}

@media only screen and (min-width: 1030px) {
  .best-explore-box .height-children {
    height: auto;
    padding-bottom: 350px !important;
  }

  .best-explore-box .puller-explore-top-product {
    height: 100vh;
  }
}

@media only screen and (max-width: 1030px) {
  .best-explore-box .height-children {
    height: auto;
    padding-bottom: 90px !important;
  }
  .best-explore-box-porto .height-children {
    height: calc(100vh - 260px);
    padding-bottom: 90px !important;
  }

  .best-explore-box .puller-explore-top-product {
    height: 100vh;
  }
}

