.best-tab .rmc-tabs-tab-bar-tab-active {
  font-weight: 700;
  color: var(--green-default) !important;
}

.common-best-tab .am-tabs-default-bar-tab-active {
  font-weight: 700 !important;
  color: var(--green-default) !important;
}

.rmc-tabs-tab-bar-tab {
  color: var(--text-primary) !important;
}

.rmc-tabs-tab-bar-top {
  border-bottom: 2px solid var(--border) !important;
}

.rmc-tabs-tab-bar-top .rmc-tabs-tab-bar-underline {
  bottom: -2px;
}

.best-tab-sticky .rmc-tabs-tab-bar-wrap {
  position: fixed;
  top: 44px;
  width: 100%;
  z-index: 10;
  overscroll-behavior: 0;
}

.best-tab-sticky .rmc-tabs-content-wrap {
  padding-top: 45px;
}

.institution-best-tab-sticky .rmc-tabs-content-wrap {
  padding-top: 0;
}

.best-tab .rmc-tabs-content-wrap {
  min-height: calc(100vh - 40px);
  overscroll-behavior: none;
}
